import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Button,
  Skeleton,
} from "@mui/material";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
// import { useNavigate } from "react-router-dom";
import CreateUser from "./createUser";
import { axiosAPI } from "../../Axios/Axios";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import DeleteUser from "./deleteUser";
import EditUser from "./editUser";

function UserList() {
  const Labels = useSelector((state) => state.allLabels.labels);
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [listRecruiter, setListRecruiter] = useState([]);

  const [deleteUser, setDeleteUser] = useState(false);
  const [userid, setUserId] = useState("");
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const [counter, setCounter] = useState(1);

  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  const deleteUserById = (id) => {
    setDeleteUser(true);
    setUserId(id);
  };
  const [loading, setloading] = useState(true);
  const getAllRecruiter = async () => {
    try {
      const res = await axiosAPI.post("/listAllRecruiter");
      const { data, status } = res.data;
      if (status) {

        const newData = data
        newData.forEach((obj, index) => {
          obj.objid = index + 1;
        });

        setListRecruiter(newData);

        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const openEditModal = () => {
    setEditUser(true);
  };
  const closeEditModal = () => {
    setEditUser(false);
  };

  useEffect(() => {
    getAllRecruiter();

  }, []);

  const skeletonRows = Array(6)
    .fill()
    .map((_, index) => (
      <TableRow
        key={index}
        sx={{
          borderTop: "10px solid #EAEFF1",
          borderBottom: "10px solid #EAEFF1",
          background: "#fff",
        }}
      >
        <TableCell component="th" scope={isSmallScreen ? "column" : "row"}>
          <Skeleton
            variant="rectangular"
            width={`100%`}
            height={60}
            style={{ margin: "5px auto" }}
          />
        </TableCell>
        {/* Repeat TableCell as many times as needed */}
      </TableRow>
    ));


  return (
    <>
      <ThemeProvider theme={theme}>
        <CreateUser
          open={createUser}
          handleClose={() => setCreateUser(false)}
          getAllRecruiter={getAllRecruiter}
        />
        <EditUser open={editUser} handleClose={closeEditModal} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ color: "#1C614E" }}>
            {Labels?.users}
          </Typography>
          <CustomButton
            onClick={() => setCreateUser(true)}
            label={Labels?.createUser}
          />
        </Stack>

        <TableContainer sx={{ my: 2 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderTop: "10px solid #EAEFF1",
                  borderBottom: "10px solid #EAEFF1",
                  background: "rgba(3, 132, 97, 0.3)",
                }}
              >
                <TableCell sx={{ fontWeight: 600 }}>{Labels?.sno}</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>{Labels?.name}</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.designation}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.emailAddress}{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels.JobsPosted}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.actions}{" "}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <>
                  {" "}
                  {Array(6)
                    .fill()
                    .map(() => {
                      return (
                        <TableRow
                          sx={{
                            borderTop: "10px solid #EAEFF1",
                            borderBottom: "10px solid #EAEFF1",
                            background: "#fff",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope={isSmallScreen ? "column" : "row"}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              height={23}
                              style={{ margin: "2px auto" }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}{" "}
                </>
              ) : (
                listRecruiter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow
                      key={user.name}
                      sx={{
                        borderTop: "10px solid #EAEFF1",
                        borderBottom: "10px solid #EAEFF1",
                        background: "#fff",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope={isSmallScreen ? "column" : "row"}
                      >
                        {user?.objid}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope={isSmallScreen ? "column" : "row"}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell>{user.designation}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.jobCount}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {/* <Button variant="text" color="success" onClick={openEditModal}>
                        Edit
                      </Button> */}
                          {user.jobCount == 0 && (
                            <DeleteIcon
                              sx={{
                                width: 28,
                                height: 28,
                                color: "#d32f2f",
                              }}
                              onClick={() => deleteUserById(user.userid)}
                            />
                          )}

                          {user.jobCount != 0 && (
                            <DeleteForeverIcon
                            onClick={(e) => warningNotify(Labels.Cannotdeletethisstaff)}
                              sx={{
                                width: 28,
                                height: 28,
                                color: "#d32f2f",
                              }}
                            />
                          )}
                          {/* <Button
                        variant="text"
                        color="error"
                        onClick={() => deleteUserById(user.userid)}
                      >
                        Delete
                      </Button> */}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={listRecruiter.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
        />
        <DeleteUser
          open={deleteUser}
          handleClose={() => setDeleteUser(false)}
          userID={userid}
          getAllRecruiter={getAllRecruiter}
        />
      </ThemeProvider>
    </>
  );
}

export default UserList;
