import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "../../Components/InputWithLabel";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Languages from "../../CommonCode/Languages";
import { HighlightOff, Visibility, VisibilityOff } from "@mui/icons-material";
import { axiosAPI } from "../../Axios/Axios";
import CustomButton from "../../Components/CustomButton";
import PhoneInput from "react-phone-input-2";
import Designation from "../../CommonCode/Designation";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";

function CreateUser({ open, handleClose, getAllRecruiter }) {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [language, setLanguage] = useState("");
  const [designation, setDesignation] = useState("");

  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
  });
  const { name, email, contactNumber, password, confirmPassword } = FormData;
  const postData = {
    name: name,
    designation: designation,
    email: email,
    contactNumber: contactNumber,
    language: language,
    password: password,
    confirmPassword: confirmPassword,
  };

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const createUserChange = async (e) => {
    const value = e.target.value;
    if (e.target.name == "password" || e.target.name == "confirmPassword") {
      setFormData({ ...FormData, [e.target.name]: value });
    } else {
      setFormData({
        ...FormData,
        [e.target.name]: profanityfn(ProfanityList, value),
      });
    }
  };
  const [countryCode, setCountryCode] = useState("1");
  let handleOnChange = (value) => {
    setCountryCode(value);
  };

  const profanityChecker = () => {
    let profanityDetected;
    for (const key in postData) {
      if (profanitySend(ProfanityList, postData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      createUser();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const createUser = async (e) => {
    setIsButtonDisabled(true);
    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;
    if (name === "") {
      errorNotify(Labels.nameRequired);
      setIsButtonDisabled(false);
    } else if (name.length > 200) {
      errorNotify(Labels.nameValidation);
      setIsButtonDisabled(false);
    } else if (!designation) {
      errorNotify(Labels.designationRequired);
      setIsButtonDisabled(false);
    } else if (!isValidEmail(email)) {
      errorNotify(Labels.emailValidation);
      setIsButtonDisabled(false);
    } else if (email.length > 100) {
      errorNotify(Labels.emailLengthValidation);
      setIsButtonDisabled(false);
    } else if (!countryCode) {
      errorNotify(Labels.countryCodeRequired);
      setIsButtonDisabled(false);
    } else if (!contactNumber) {
      errorNotify(Labels.contactNumberRequired);
      setIsButtonDisabled(false);
    } else if (!/^\d*$/.test(contactNumber)) {
      errorNotify(Labels.phoneNumberValidation);
      setIsButtonDisabled(false);
    } else if (contactNumber.length < 7) {
      errorNotify(Labels.contactMinValidation);
      setIsButtonDisabled(false);
    } else if (contactNumber.length > 14) {
      errorNotify(Labels.contactMaxValidation);
      setIsButtonDisabled(false);
    } else if (language === "") {
      errorNotify(Labels.languageRequired);
      setIsButtonDisabled(false);
    } else if (password === "") {
      errorNotify(Labels.passwordBlank);
      setIsButtonDisabled(false);
    }

    if (password.length < minLength || password.length > maxLength) {
      errorNotify(Labels.Passwordmustbebetween8and30characters);
      setIsButtonDisabled(false);
    } else if (!uppercaseRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
      setIsButtonDisabled(false);
    } else if (!numericRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
      setIsButtonDisabled(false);
    } else if (!specialCharRegex.test(password)) {
      errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
      setIsButtonDisabled(false);
    }

    if (confirmPassword === "") {
      errorNotify(Labels.confirmPasswordBlank);
      setIsButtonDisabled(false);
    } else if (password !== confirmPassword) {
      errorNotify(Labels.passwordMisMatch);
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(false);
      await axiosAPI
        .post("/addRecruiter", postData)
        .then((res) => {
          const { success, message } = res.data;
          if (success) {
            succesNotify(Labels.accountCreated);
            setIsButtonDisabled(false);
            handleClose();
            setFormData("");
            getAllRecruiter();
          } else {
            if (res.data.message == "The email has already been taken.") {
              errorNotify(Labels.EmailTaken);
            }
            setIsButtonDisabled(false);
          }
        })
        .catch((res) => {
          warningNotify(Labels.credentialValidation);
        });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ color: "#2B4947" }}>
            {Labels?.createUser}
          </Typography>
          <HighlightOff
            sx={{
              color: "#1C614E",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        {/* -------------------- Name -------------------- */}
        <InputWithLabel
          style={{ width: "100%" }}
          InputLabel={Labels?.name}
          label={Labels?.name}
          type="text"
          name="name"
          value={name}
          required
          onChange={(e) => createUserChange(e)}
        />
        {/* -------------------- Designation -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <label>{Labels?.designation}</label>
          <span style={{ color: "red" }}>*</span>
          <br />
          <Designation
            value={designation}
            setValue={setDesignation}
            style={{ width: "100%", fontSize: "14px" }}
          />
        </div>
        {/* -------------------- Email -------------------- */}
        <InputWithLabel
          style={{ width: "100%" }}
          InputLabel={Labels?.emailAddress}
          label={Labels?.emailAddress}
          type="email"
          name="email"
          value={email}
          required
          onChange={(e) => createUserChange(e)}
        />
        {/* -------------------- Contact Number -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <label>{Labels?.ContactNumber}</label>
          <span style={{ color: "red" }}>*</span>
          <br />
          <Stack direction="row">
            <PhoneInput
              country={"us"}
              style={{ width: "110px" }}
              inputProps={{ required: true, readOnly: true }}
              value={countryCode}
              onChange={handleOnChange}
              readOnly
            />
            <InputWithLabel
              style={{ width: "100%" }}
              label={Labels?.ContactNumber}
              type="text"
              name="contactNumber"
              display="true"
              value={contactNumber}
              onChange={(e) => createUserChange(e)}
            />
          </Stack>
        </div>
        {/* -------------------- Language -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <label>{Labels?.preferredLanguage}</label>
          <span style={{ color: "red" }}>*</span>
          <br />
          <Languages
            style={{
              width: "100%",
            }}
            selectLabel={Labels?.preferredLanguage}
            value={language}
            setValue={setLanguage}
          />
        </div>
        {/* -------------------- Password -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <label>{Labels?.password}</label>
          <span style={{ color: "red" }}>*</span>
          <br />
          <OutlinedInput
            type={showPassword1 ? "text" : "password"}
            placeholder={Labels?.password}
            name="password"
            value={password}
            onChange={(e) => createUserChange(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                  edge="end"
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{ width: "100%" }}
          />
        </div>
        {/* -------------------- Confirm Password -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <label>{Labels?.confirmPassword}</label>
          <span style={{ color: "red" }}>*</span>
          <br />
          <OutlinedInput
            type={showPassword2 ? "text" : "password"}
            placeholder={Labels?.confirmPassword}
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => createUserChange(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{ width: "100%" }}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ py: 1, px: 4 }}>
        <CustomButton
          label={Labels?.createUser}
          style={{
            borderRadius: 1,
            fontSize: "14px",
          }}
          onClick={profanityChecker}
        />
      </DialogActions>
    </Dialog>
  );
}

export default CreateUser;
