import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import JobCategory from "../../CommonCode/JobCategory";
import BusinessCategory from "../../CommonCode/BusinessCategory";
import InputWithLabel from "../../Components/InputWithLabel";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Languages from "../../CommonCode/Languages";

import { HighlightOff, Visibility, VisibilityOff } from "@mui/icons-material";
import { axiosAPI } from "../../Axios/Axios";
import CustomButton from "../../Components/CustomButton";
import PhoneInput from "react-phone-input-2";
import { getAllCompanies1 } from "../../CommonCode/AddedCompanies";
import AddedCountry from "./CompanyCountryadded";
import { profanityfn } from "../../Profanity/Profanity";
// import Designation from "../../CommonCode/Designation"; { open, handleClose, getAllCompanies }

function CreateCompany({ open, handleClose, getAllCompanies }) {
  //// console.log("open", open)
  // function CreateCompany({ open, onChildData1, handleClose, getAllCompanies, onChildValueChange }) {
  const redirect = useNavigate();

  const Labels = useSelector((state) => state.allLabels.labels);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [country, setcountry] = useState(null);
  const [state, setState] = useState(null);
  const [countryCode, setCountryCode] = useState("1");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [updateComponent, setUpdateComponent] = useState(0);
  const [consultancyBranch, setconsultancyBranch] = useState([]);
  const [country1, setcountry1] = useState(null);
  const [state1, setState1] = useState(null);
  // Call the callback function
  // onChildData(updateComponent)

  // onChildData1(updateComponent)
  //// console.log(country,"country");
  //// console.log(state,"state");
  const [FormData, setFormData] = useState({
    companyName: "",
    email: "",
    contactNumber: "",
    city: "",
    address: "",
    website: "",
    city1: "",
    address1: "",
  });

  const {
    companyName,
    email,
    contactNumber,
    city,
    address,
    website,
    city1,
    address1,
  } = FormData;

  const postData = {
    companyName: companyName,
    email: email,
    country: country?.id.toString(),
    state: state?.id.toString(),
    city: city,
    countryCode: countryCode,
    address: address,
    website: website,
    contactNumber: contactNumber,
    businessCategory: businessCategories.toString().replace(/(^,|,$|,,+)/g, ""),
    consultancyBranches: JSON.stringify(consultancyBranch),
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const createCompanyChange = async (e) => {
    const value = e.target.value;
    setFormData({
      ...FormData,
      [e.target.name]: profanityfn(ProfanityList, value),
    });
  };

  let handleOnChange = (value) => {
    setCountryCode(value);
  };

  const createCompany = async (e) => {
    e.preventDefault();
    setUpdateComponent(updateComponent + 1);
    setIsButtonDisabled(true);
    // if (businessCategories.length !== 0) {
    //   employerData.businessCategory = businessCategories
    //     .toString()
    //     .replace(/(^,|,$|,,+)/g, "");
    // } else {
    //   employerData.businessCategory = null;
    // }
    // else if (name.length > 200) {
    //       errorNotify(Labels.nameValidation);
    //       setIsButtonDisabled(false);
    //     } else if (!designation) {
    //       errorNotify(Labels.designationRequired);
    //       setIsButtonDisabled(false);
    //     }

    //     if (!isValidEmail(email)) {
    //   errorNotify(Labels.emailValidation);
    //   setIsButtonDisabled(false);
    // } else if (email.length > 100) {
    //   errorNotify(Labels.emailLengthValidation);
    //   setIsButtonDisabled(false);
    // }
    if (companyName === "") {
      errorNotify(Labels.companyNameRequired);
      setIsButtonDisabled(false);
    } else if (businessCategories.length == 0) {
      errorNotify("Company Sector is Required");
      setIsButtonDisabled(false);
    } else if (address == "") {
      errorNotify(Labels.AddressisRequired);
      setIsButtonDisabled(false);
    } else if (city == "") {
      errorNotify(Labels.CityisRequired);
      setIsButtonDisabled(false);
    } else if (!contactNumber) {
      errorNotify(Labels.contactNumberRequired);
      setIsButtonDisabled(false);
    } else if (!/^\d*$/.test(contactNumber)) {
      errorNotify(Labels.phoneNumberValidation);
      setIsButtonDisabled(false);
    } else if (contactNumber.length < 7) {
      errorNotify(Labels.contactMinValidation);
      setIsButtonDisabled(false);
    } else if (contactNumber.length > 14) {
      errorNotify(Labels.contactMaxValidation);
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(false);
      await axiosAPI
        .post("/addCompany", postData)
        .then((res) => {
          const { success, message } = res.data;
          if (success) {
            succesNotify(Labels.accountCreated);
            setIsButtonDisabled(false);
            handleClose();
            setFormData("");
            setTimeout(() => {
              getAllCompanies();
            }, 200);
          } else {
            errorNotify(message);
            setIsButtonDisabled(false);
          }
        })
        .catch((res) => {
          warningNotify(Labels.credentialValidation);
        });
    }
  };
  // const handleClose = () => {
  //   // Add your logic to close the dialog
  //   setOpen(false);
  // };

  //// console.log(open, "value of param in child part", handleClose)
  const addinfo = () => {
    if (country1 && state1) {
      const countryId = country1.name;
      const country = country1.id;
      const stateId = state1.name;
      const state = state1.id;

      const newData = {
        countryId,
        country,
        stateId,
        state,
        city: city1,
        address: address1,
      };

      setconsultancyBranch((prevconsultancyBranch) => [
        ...prevconsultancyBranch,
        newData,
      ]);
    }
    else{
      errorNotify(Labels.Pleaseaddcountryandstate)
    }
    // setcountry1("");
    // setState1("")
    // setFormData({ ...FormData, city1: "" });
    // setFormData({ ...FormData, address1: "" });
  };

  const removebranches = (index) => {
    const updatedbranches = [...consultancyBranch];
    updatedbranches.splice(index, 1);
    setconsultancyBranch(updatedbranches);
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ color: "#2B4947" }}>
            {Labels?.CreateCompany}
          </Typography>
          <HighlightOff
            sx={{
              color: "#1C614E",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* --------------------CompanyName -------------------- */}
          <Grid item xs={12} md={12}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels?.companyName}
              label={Labels?.name}
              type="text"
              name="companyName"
              value={companyName}
              required
              onChange={(e) => createCompanyChange(e)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* --------------------- jobCategory -------------------------- */}
          <Grid item xs={12} md={6}>
            <Box sx={{ m: "10px 5px" }}>
              <label>
                {Labels?.BusinessIndustry}
                <span style={{ color: "red" }}>*</span>
              </label>
              <BusinessCategory
                value={businessCategories}
                setValue={setBusinessCategories}
                onChange={(e) => createCompanyChange(e)}
              />
            </Box>
          </Grid>
          {/* -------------------- Email -------------------- */}
          <Grid item xs={12} md={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels.emailAddress}
              label={Labels?.emailAddress}
              type="email"
              name="email"
              value={email}
              onChange={(e) => createCompanyChange(e)}
            />
          </Grid>
        </Grid>
        {/* -------------------- Contact Number -------------------- */}
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.ContactNumber}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Stack direction="row">
                <PhoneInput
                  country={"us"}
                  style={{ width: "110px" }}
                  inputProps={{ required: true, readOnly: true }}
                  value={countryCode}
                  onChange={handleOnChange}
                  readOnly
                />
                <InputWithLabel
                  style={{ width: "100%" }}
                  label={Labels?.ContactNumber}
                  type="text"
                  name="contactNumber"
                  display="true"
                  value={contactNumber}
                  onChange={(e) => createCompanyChange(e)}
                />
              </Stack>
            </div>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Box sx={{ m: "10px 5px" }}>
              <label>
                {Labels?.Country}
                <span style={{ color: "red" }}>*</span>
              </label>
              <Country
                value={country}
                setValue={setcountry}
                setStateId={setState}
                onChange={(e) => createCompanyChange(e)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ m: "10px 5px" }}>
              <label>
                {Labels?.State}
                <span style={{ color: "red" }}>*</span>
              </label>
              <State value={state} setValue={setState} country={country} />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={
                <>
                  {Labels?.City}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              label={Labels?.City}
              name="city"
              // value={email}
              onChange={(e) => createCompanyChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={
                <>
                  {Labels?.Address}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              label={Labels?.Address}
              name="address"
              value={address}
              onChange={(e) => createCompanyChange(e)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.ContactNumber}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Stack direction="row">
                <PhoneInput
                  country={"us"}
                  style={{
                    width:
                      countryCode?.length === 1
                        ? "120px"
                        : countryCode?.length === 2
                        ? "140px"
                        : countryCode?.length === 3
                        ? "160px"
                        : "auto",
                  }}
                  inputProps={{ required: true, readOnly: true }}
                  value={countryCode}
                  onChange={handleOnChange}
                  readOnly
                />
                <InputWithLabel
                  style={{ width: "100%" }}
                  label={Labels?.ContactNumber}
                  type="text"
                  name="contactNumber"
                  display="true"
                  value={contactNumber}
                  onChange={(e) => createCompanyChange(e)}
                />
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWithLabel
              style={{ width: "100%" }}
              InputLabel={Labels?.Website}
              label={Labels?.Website}
              name="website"
              value={website}
              onChange={(e) => createCompanyChange(e)}
            />
          </Grid>
          {/* -----------------------------new fields------------------------------------------ */}
          <Typography variant="h6" sx={{ color: "#2B4947", marginLeft: 2 }}>
            {Labels.AddBranches}
          </Typography>
          {consultancyBranch.length !== 0 && (
            <Grid container spacing={1} marginLeft={2}>
              {consultancyBranch.map((data, index) => (
                <Grid
                  item
                  xs={10}
                  md={6}
                  lg={6}
                  key={index}
                  my={2}
                  direction="row"
                >
                  <Box
                    // onClick={() => handleItemPress(data,index)}
                    key={index}
                    sx={{
                      p: 2,
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      mb: 2,
                      cursor: "pointer",
                      xs: "100%",
                      width: {
                        xs: "100%", // Set width to 100% on xs screens
                        sm: "80%", // Set width to 80% on sm and larger screens
                      },
                    }}
                    // onClick={() => updateLanguage(val)}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        {`Branches`}
                      </Typography>
                      {/* <Typography
                                  variant="subtitle2"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {`${data?.state}`}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {`${data?.city}`}
                                </Typography> */}

                      <Stack direction="row" alignItems="center">
                        <HighlightOffTwoToneIcon
                          sx={{
                            cursor: "pointer",
                            fontsize: "1.2rem",
                            color: "rgba(0, 0, 0, 0.59)",
                          }}
                          onClick={() => removebranches(index)}
                        />
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                          }}
                        >
                          {Labels?.country}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                            mx: 2,
                            width: "200px",
                          }}
                        >
                          {/* {data?.Wlevel} */}
                          {`${data?.countryId}`}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                          }}
                        >
                          {Labels?.state}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                            mx: 2,
                            width: "200px",
                          }}
                        >
                          {/* {data?.Wlevel} */}
                          {`${data?.stateId}`}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                          }}
                        >
                          {Labels?.city}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                            mx: 2,
                            width: "200px",
                          }}
                        >
                          {/* {data?.Rlevel} */}
                          {`${data?.city}`}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                          }}
                        >
                          {Labels?.address}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                            mx: 2,
                            width: "200px",
                          }}
                        >
                          {/* {data?.Rlevel} */}
                          {`${data?.address.slice(0, 20)}`}...
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack direction="row">
                        {/* <Typography
                              variant="subtitle2"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              Value 
                            </Typography> */}
                        <Typography
                          variant="caption"
                          sx={{
                            color: "rgba(0, 0, 0, 0.59)",
                            mx: 2,
                            width: "200px",
                          }}
                        >
                          {/* {`${data?.value.slice(0, 70)}`}.... */}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid container spacing={2} sx={{ marginLeft: 0.5 }}>
            {/* <Grid item xs={12} md={6}>
            <div style={{ margin: "10px 5px" }}>
              <label>{Labels?.ContactNumber}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Stack direction="row">
                <PhoneInput
                  country={"us"}
                  style={{ width: "110px" }}
                  inputProps={{ required: true, readOnly: true }}
                  value={countryCode}
                  onChange={handleOnChange}
                  readOnly
                />
                <InputWithLabel
                  style={{ width: "100%" }}
                  label={Labels?.ContactNumber}
                  type="text"
                  name="contactNumber"
                  display="true"
                  value={contactNumber}
                  onChange={(e) => createCompanyChange(e)}
                />
              </Stack>
            </div>
          </Grid> */}
            <Grid item xs={12} md={6}>
              <Box sx={{ m: "10px 5px" }}>
                <label>
                  {Labels?.Country}
                  <span style={{ color: "red" }}></span>
                </label>
                <Country
                  value={country1}
                  setValue={setcountry1}
                  setStateId={setState}
                  onChange={(e) => createCompanyChange(e)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ m: "10px 5px" }}>
                <label>
                  {Labels?.State}
                  <span style={{ color: "red" }}></span>
                </label>
                <State value={state1} setValue={setState1} country={country1} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginLeft: 0.5 }}>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                style={{ width: "100%" }}
                InputLabel={Labels?.City}
                label={Labels?.City}
                name="city1"
                // value={email}

                onChange={(e) => createCompanyChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                style={{ width: "100%" }}
                InputLabel={Labels?.Address}
                label={Labels?.Address}
                name="address1"
                value={address1}
                onChange={(e) => createCompanyChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2} alignItems="center" justifyContent="center">
          <CustomButton
            label={Labels.Add}
            style={{ padding: "5px 25px" }}
            onClick={addinfo}
            // disabled={isButtonDisable}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ py: 1, px: 4 }}>
        <CustomButton
          label={Labels?.create}
          style={{
            borderRadius: 1,
            fontSize: "14px",
          }}
          onClick={createCompany}
        />
      </DialogActions>
    </Dialog>
  );
}

export default CreateCompany;
