import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteValidator = () => {

  const routesArray = [
    "/ErrorPage",
    "/login",
    "/emppwdchange",
    "/signup",
    "/",
    "/landingpage",
    "/about",
    "/jobSearchPrelogin",
    "/contact",
    "/jobSearchPrelogin/:id",
    "/allCompanies",
    "/allCategories",
    "/company/:id",
    "/employerDashboard",
    "/employerProfile",
    "/user",
    "/Createjob",
    "/employerJobList",
    "/matchingJobSeekers/:id",
    "/markedJobSeekers/:id",
    "/appliedJobSeekers/:id",
    "/shortListedJobSeekers/:id",
    "/jobEnquires/:id",
    "/jobInfo/:id",
    "/editJob/:id",
    "/searchJobSeeker",
    "/jobSeekerProfileView/:id",
    "/CompanyList",
    "/create-group",
    "/search-groupList",
    "/my-groupList",
    "/group-detail/:id",
    "/edit-group/:id",
    "/forumList",
    "/myForumList",
    "/create-Forum",
    "/forum-detail/:id",
    "/myForum-detail/:id",
    "/edit-forum/:id",
    "/chats",
    "/messages",
    "/notification",
    "/jobList",
    "/detailedJobView/:id",
    "/recommendedJobs",
    "/similarJobs",
    "/appliedJobs",
    "/jobseekerProfile",
    "/jobseekerDashboard",
    "/Companydetailview/:id",
    "/createGroup",
    "/searchGroupList",
    "/myGroupList",
    "/groupDetail/:id",
    "/myGroupDetail/:id",
    "/editGroup/:id",
    "/forum",
    "/myForum",
    "/createForum",
    "/forumDetail/:id",
    "/myForumDetail/:id",
    "/editForum/:id",
    "/chat",
    "/message",
    "/notifications"
  ];
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const isRouteValid = routesArray.some((route) => {
      const regexRoute = new RegExp(`^${route.replace(/:\w+/g, "([^/]+)")}$`, 'i');
      return regexRoute.test(path);
    });

    if (!isRouteValid) {
      window.location.href = "/ErrorPage"
    }
  }, [path]);

  return null;
};

export default RouteValidator;
