import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";
import { getAllLabels } from "./AllLabels";
import { useDispatch } from "react-redux";
const initialState = {
  loading: false,
  error: "",
  loginDetails: {},
};

const createNewLoginDetails = createAsyncThunk(
  "api/login",
  async (postData) => {
    const {
      empEmailId,
      empPassword,
      redirect,
      errorNotify,
      succesNotify,
      setIsButtonDisabled,
    } = postData;
    try {
      const loginCredentials = {
        username: empEmailId,
        password: empPassword,
        userRole: "2",
        language:localStorage.getItem("language")
      };
      const result = await axiosAPI.post("/login", loginCredentials);
      const { success, message, data } = result.data;
      
      if (success) {
        
        localStorage.setItem("employerToken", data.token);

        if (data?.companyType) {
            localStorage.setItem("employerType", data.companyType);
        }
    
        if (data?.userType) {
            localStorage.setItem("userType", data.userType);
        }
    
        if (data?.resettedByAdmin) {
            localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
            if (data.resettedByAdmin === 1) {
                redirect("/emppwdchange");
            } 
        }
    
        if (data?.preferredLanguage) {
            localStorage.setItem("language", data?.preferredLanguage);
        if (data.resettedByAdmin === 0) {
              redirect("/employerDashboard"); 
              succesNotify(message);
          }
        } else {
            localStorage.setItem("language", "en");
        }
        
        setIsButtonDisabled(false);
        return data;
    } else {
        errorNotify(message);
        setIsButtonDisabled(false);
    }
    
    } catch (error) {
      //// console.log(error.message);
      setIsButtonDisabled(false);
    }
  }
);

const createLoginDetailsSlice = createSlice({
  name: "createLoginDetails",
  initialState,
  reducers: {
    updateLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
  },
  extraReducers: {
    [createNewLoginDetails.pending]: (state) => {
      state.loading = true;
    },
    [createNewLoginDetails.fulfilled]: (state, { payload }) => {
      state.loginDetails = payload;
      state.loading = false;
    },
    [createNewLoginDetails.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export const { updateLoginDetails } = createLoginDetailsSlice.actions;

export { createNewLoginDetails };

export default createLoginDetailsSlice.reducer;
