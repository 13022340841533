import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
} from "../CommonCode/Commonfunc";
import { createNewLoginDetails } from "../../redux/CreateLoginDetailSlice";
import { axiosAPI } from "../Axios/Axios";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import InputWithLabel from "../Components/InputWithLabel";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "../Components/CustomButton";
import ForgotPasswordModel from "../Jobseeker/Login/ForgotPasswordModel";
import {profanityfn}  from "../Profanity/Profanity";
import {
  doSignInWithFacebook,
  doSignInWithGoogle,
} from "../../AuthConfig/Auth";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Popup from "./CustomSignup/Popup";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EmployerLogin() {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [forgetPasswordModal, setforgetPasswordModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [employerDetl, setEmployerDetl] = useState({
    empEmailId: "",
    empPassword: "",
  });
  const { empEmailId, empPassword } = employerDetl;
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const getEmployerdetl = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.name == "empPassword") {
      setEmployerDetl({ ...employerDetl, [e.target.name]: value });
    } else {
      setEmployerDetl({ ...employerDetl, [e.target.name]: profanityfn(ProfanityList,value) });
    }
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const submitEmployerData = (e) => {
    e?.preventDefault();
    //// console.log(employerDetl);
    setIsButtonDisabled(true);
    if (!isValidEmail(empEmailId)) {
      errorNotify(Labels.emailValidation);
      setIsButtonDisabled(false);
    } else if (empPassword === "") {
      errorNotify(Labels.passwordValidation);
      setIsButtonDisabled(false);
    } else {
      dispatch(
        createNewLoginDetails({
          empEmailId,
          empPassword,
          redirect,
          errorNotify,
          succesNotify,
          setIsButtonDisabled,
        })
      );
    }
  };
  const TosignUp = () => {
    redirect("/signup", { state: 2 });
  };


  const [opengooglepopup, setopengooglepopup] = useState(false);
  const [openfacebookpopup, setopenfacebookpopup] = useState(false);

  const onGoogleSignIn = (e) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithGoogle()
        .then((res) => {
          axiosAPI
            .post("/login", {
              username: res.providerData[0].email,
              password: res.providerData[0].uid,
              userRole: "2",
              language:localStorage.getItem("language")
            })
            .then((response) => {
              const { success, message, data } = response.data;
              if (success) {
                localStorage.setItem("employerToken", data.token);
                if (data?.companyType) {
                  localStorage.setItem("employerType", data.companyType);
                }
                if (data?.userType) {
                  localStorage.setItem("userType", data.userType);
                }
                if (data?.resettedByAdmin) {
                  localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
                  if (data.resettedByAdmin === 1) {
                    redirect("/emppwdchange");
                  }
                }
                if (data?.preferredLanguage) {
                  localStorage.setItem("language", data?.preferredLanguage);
                  if (data.resettedByAdmin === 0) {
                    redirect("/employerDashboard");
                    succesNotify(message);
                  }
                } else {
                  localStorage.setItem("language", "en");
                }

                setIsButtonDisabled(false);
                return data;
              } else {
                setIsButtonDisabled(false);
                setopengooglepopup(true);
              }
            })
            .catch((e) => {
              setIsButtonDisabled(false);
            });
        })
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };
  const onFacebookSignIn = (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithFacebook()
        .then((res) => {})
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };

  return (
    <>
      <Popup
        openpopup={opengooglepopup}
        setopenpopup={setopengooglepopup}
        user={"Employer"}
        loginType={"Google"}
      />
      <Popup
        openpopup={openfacebookpopup}
        setopenpopup={setopenfacebookpopup}
        user={"Employer"}
        loginType={"Facebook"}
      />

      <ForgotPasswordModel
        open={forgetPasswordModal}
        handleClose={setforgetPasswordModal}
        empEmail={empEmailId}
      />
      {/* ----------------------Email Address---------------- */}
      <InputWithLabel
        style={{ width: "100%", mb: 2 }}
        InputLabel={Labels?.emailAddress}
        label={Labels?.emailAddress}
        type="text"
        name="empEmailId"
        value={empEmailId}
      
        onChange={(e) => getEmployerdetl(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !isSubmitted) {
            submitEmployerData();
            setIsSubmitted(true);
          }
        }}
      />
      {/* ----------------------Password---------------- */}
      <div style={{ margin: "10px 5px" }}>
        <style>
          {`
          input[type=password]::-ms-reveal,
          input[type=password]::-ms-clear {
            display: none;
          }
        `}
        </style>
        <label>
          {" "}
          <label>{Labels?.password}</label>
        </label>
        <br />
        <OutlinedInput
          type={showPassword1 ? "text" : "password"}
          placeholder="Password"
          sx={{
            width: "100%",
            mb: 2,
          }}
          name="empPassword"
          value={empPassword}
          inputMode="none"
          onChange={(e) => getEmployerdetl(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isSubmitted) {
              submitEmployerData();
              setIsSubmitted(true);
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword1}
                onMouseDown={handleMouseDownPassword1}
                edge="end"
              >
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            sx={{
              color: " #1C614E",
              "&.Mui-checked": {
                color: "#1C614E",
              },
            }}
          />
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: "15px",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {Labels?.rememberMe}
          </Typography>
        </Stack>
        {/* ----------------------Forget Password---------------- */}
        <Button
          variant="Text"
          sx={{
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 300,
          }}
          onClick={() => setforgetPasswordModal(true)}
        >
          {Labels?.forgotPassword}
        </Button>
      </div>
      {/* ----------------------Signin button ---------------- */}
      <CustomButton
        label={Labels?.signIn}
        style={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#FFFFFF",
          width: "100%",
          margin: "10px auto",
          borderRadius: "4px",
        }}
        onClick={submitEmployerData}
        disabled={isButtonDisabled}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Item>
            <button
              disabled={isSigningIn}
              className="social-google-btn"
              onClick={() => {
                onGoogleSignIn();
              }}
            >
              <i class="fa fa-google fa-fw"> </i> {Labels.LoginwithGoogle}
            </button>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <button
              disabled={isSigningIn}
              className="social-facebook-btn"
              onClick={(e) => {
                setopenfacebookpopup(true);
              }}
            >
              <i class="fa fa-facebook fa-fw"></i> {Labels.LoginwithFacebook}
            </button>
          </Item>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 300,
          }}
        >
          {Labels.Donthaveanaccount}
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "15px",
            fontWeight: 600,
          }}
          onClick={TosignUp}
        >
          {Labels?.signUp}
        </Button>
      </div>
    </>
  );
}
