import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  Container,
  Grid,
  Switch,
  Chip,
  Skeleton,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TransgenderIcon from "@mui/icons-material/Transgender";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CustomButton from "../../Components/CustomButton";
import WorkIcon from "@mui/icons-material/Work";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import PieChartIcon from "@mui/icons-material/PieChart";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import JobSeekerProfileSideview from "./JobSeekerProfileSideview";
import { useState } from "react";
import EploymentModal from "./EploymentModal";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { useEffect } from "react";
import Gender from "../../CommonCode/Gender";
import Designation from "../../CommonCode/Designation";
import DesignationProfile from '../../CommonCode/DesignationProfile'
import EducationModal from "./EducationModal";
import SkillsaddModal from "./SkillsaddModal";
import CoverLetterModal from "./CoverLetterModal";
import EmployementUpdateModal from "./EmployementUpdateModal";
import EducationUpdateModal from "./EducationUpdateModal";
import PreferedLocationModal from "./PreferedLocationModal";
import JobSeekerNavbar from "./JobSeekerNavbar";
import { useStyles } from "../../CommonCode/Commonfunc";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { format, subYears } from "date-fns";
import PublicIcon from "@mui/icons-material/Public";
import BungalowIcon from "@mui/icons-material/Bungalow";
import HomeIcon from "@mui/icons-material/Home";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmploymentDeleteAlert from "./JobseekerDashboard/EmploymentDeleteAlert";
import IconLabelTextField from "../../Components/iconLabelText";
import "./style.css";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import EmployerPasswordChangeModal from "../../Employer/EmployerProfile/EmployerPasswordChangeModal";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import LanguageAddModal from "./LanguageAddModal";
import LanguageEditModal from "./LanguageEditModal";
import CircleIcon from "@mui/icons-material/Circle";
import { updateLoadingState } from "../../../redux/Loader";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SkillsNew from "../../CommonCode/SkillsNew";
import {profanityfn, profanitySend}  from "../../Profanity/Profanity";

const JobseekerProfile = ({routeChange}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isButton1Disable, setButton1Disable] = useState(false);
  const [isButton2Disable, setButton2Disable] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [employments, setEmployments] = useState([]);
  const [educations, seteducations] = useState([]);
  const [skillsId, setSkillsId] = useState([]);
  const [preferedLocations, setPreferedLocations] = useState([]);
  const [skillsName, setskillsName] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [userGender, setUserGender] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState("");
  const [resume, setResume] = useState("");
  const [jsresume, uploadjsresume] = useState(null);
  const [updateEmployment, setUpdateEmployment] = useState({});
  const [updatedEducation, setUpdatedEducation] = useState({});
  const [isApiCalled, setIsApiCalled] = useState(0);
  const [deleteEmploymentId, setDeleteEmploymentId] = useState("");
  const [deleteEducationId, setDeleteEducationId] = useState("");
  const [deleteLanguageId, setDeleteLanguageId] = useState("");
  const [Ischecked, setIsChecked] = useState(false);
  const currentDate = new Date();
  const maxDate = subYears(currentDate, 50);
  const maxDateFormatted = format(maxDate, "yyyy-MM-dd");
  const [languageData, setLanguageData] = useState({});
  // ------------Modal Contro States---------------
  const [open, setOpen] = useState(false);
  const [skillsopen, setSkillOpen] = useState(false);
  const [openPreferedLocationsModal, setPreferedLocationsModal] =useState(false);
  const [openCoverLetter, setopenCoverLetter] = useState(false);
  const [openEducationAddModel, setopenEducationModel] = useState(false);
  const [openPasswordReset, setopenPasswordReset] = useState(false);
  const [openEmploymentUpdateModal, setemploymentUpdateModal] = useState(false);
  const [openEducationUpdateModal, setEducationUpdateModal] = useState(false);
  const [openEmploymentDeleteAlert, setEmploymentDeleteAlert] = useState(false);
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const fileInputRef = useRef(null);
  const handleAttachFile = () => {
    fileInputRef.current.click();
  };
  // ----------Loading status from redux----------------------
  const isLoading = useSelector((state) => state.Loading.isLoading);
  // ----------Modal Controls----------------------
  const openEmploymentModel = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsApiCalled(Math.random());
  };
  const openEducationModel = () => {
    setopenEducationModel(true);
  };
  const handleCloseEducationModel = () => {
    setopenEducationModel(false);
    setIsApiCalled(Math.random());
  };
  const openCoverLetterModel = () => {
    setopenCoverLetter(true);
  };
  const handleCloseCoverLetter = () => {
    setopenCoverLetter(false);
    setIsApiCalled(Math.random());
  };
  const OpenSkillModal = () => {
    setSkillOpen(true);
  };
  const skillCloseModal = () => {
    setSkillOpen(false);
    setIsApiCalled(Math.random());
  };
  const employemtUpdateModalClose = () => {
    setemploymentUpdateModal(false);
    setIsApiCalled(Math.random());
  };
  const educationUpdateModalClose = () => {
    setEducationUpdateModal(false);
    setIsApiCalled(Math.random());
  };
  const openPasswordResetModal = () => {
    setopenPasswordReset(true);
  };
  const ForgotPasswordModelClose = () => {
    setopenPasswordReset(false);
    setIsApiCalled(Math.random());
  };
  const closePreferedLocationsModal = () => {
    setPreferedLocationsModal(false);
    setIsApiCalled(Math.random());
  };
  const closeEmploymentDeleteModal = () => {
    setEmploymentDeleteAlert(false);
    setDeleteEmploymentId("");
    setDeleteEducationId("");
    setIsApiCalled(Math.random());
  };

  const handleToggle = () => {
    setIsChecked((prevChecked) => {
      sendValueToAPI(!prevChecked);
      return !prevChecked;
    });
  };

  const sendValueToAPI = (value) => {
    axiosAPI
      .post("/enableDisableTFA", { twoFactorPreferred: value ? 1 : 0 }, tokens)
      .then((res) => {})
      .catch((err) => {
        //// console.log(err);
      });
  };

  //---------------Resume Upload Api-------------
  const getResume = (e) => {
    uploadjsresume(e.target.files[0]);
    setResume(e.target.files[0].name);
  };
  const uploadResume = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("files", jsresume);
    data.append('language',localStorage.getItem("language"))
    if (!jsresume) {
      errorNotify(Labels.uploadFile);
    } else if (jsresume.type !== "application/pdf") {
      errorNotify(Labels.fileValidation);
      return;
    } else {
      const result = await axiosupload.post("/uploadResume", data, tokens);
      const { status, message } = result.data;
      if (status === "successs") {
        succesNotify(message);
        setIsApiCalled(Math.random());
      } else {
        warningNotify(message);
      }
    }
  };

  // -------------Profile Detail GET API----------------------
  useEffect(() => {
    getProfile();
  }, [isApiCalled]);
  const uid = localStorage.getItem("uid");
  const postdata = {
    jobseekerid: uid,
  };
  var {
    address1,
    // address2,
    // certifications,
    city,
    countryid,
    coverLetter,
    // createdOn,
    currentEmployer,
    currentSalary,
    dateOfBirth,
    // designation,
    displayName,
    emailid,
    expectedSalary,
    // designationName,
    // firstName,
    // jobtype,
    // jsp_id,
    // lastName,
    // lastUpdatedOn,
    phoneNo,
    gender,
    // preferredLanguage,
    profilePicPath,
    // resumePath,
    stateid,
    // tags,
    totalExperience,
    // twoFactorPreferred,
    // userid,
    // workStatus,
    // zip,
  } = profileData;
  const getProfile = async () => {
    try {
      dispatch(updateLoadingState(true));
      const result = await axiosAPI.post("/profile", postdata);
      const { success, data, message } = result.data;
      if (success === true) {
        setProfileData(data);
        setIsChecked(data.twoFactorPreferred == 1 ? true : false);
        setCountryId(
          data.countryid ? { id: data.countryid, name: data.countryName } : null
        );
        setStateId(
          data.stateid ? { id: data.stateid, name: data.stateName } : null
        );
        setUserGender(data.gender ? data.gender : "");
        setUserDesignation(data.designation ? data.designation : "");
        setEmployments(data?.employment);
        setskillsName(data?.skills);
        seteducations(data?.education);
        setSkillsId(data?.skillids);
        setPreferedLocations(data?.locations);
        setCoverLetterData(data?.coverLetter);
        setResume(data?.resumePath);
        setUserExperienceLevel(
          data.experienceLevel ? data?.experienceLevel : ""
        );
        setLanguages(data.languages);
        dispatch(updateLoadingState(false));
        routeChange(Math.random())
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //------------updating  personal information---------------
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const onChangeUserProfile = (e) => {
    const value = e.target.value;
    setProfileData({ ...profileData, [e.target.name]: profanityfn(ProfanityList,value) });
  };

  //------------ function to check DOB Validation---------------
  function isDateBefore50YearsAgo(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const today = new Date();
    const fiftyYearsAgo = new Date();
    fiftyYearsAgo.setFullYear(today.getFullYear() - 50);
    return inputDate < fiftyYearsAgo;
  }

  const sendChatProfanity = (e) => {
    const postData = {
      displayName: displayName,
      dateOfBirth: dateOfBirth,
      gender: gender,
      emailid: emailid,
      phoneNo: phoneNo,
      city: city,
      countryid: countryid,
      stateid: stateid,
      address1: address1,
      language:localStorage.getItem("language")
    };

    let profanityDetected;
    for (const key in postData) {
      if (profanitySend(ProfanityList, postData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitPersonalInformation(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitPersonalInformation = async (e) => {
    e.preventDefault();
    setButton1Disable(true);
    countryid = countryId?.id;
    stateid = stateId?.id;
    gender = userGender;
    if (!displayName) {
      errorNotify(Labels.nameRequired);
      setButton1Disable(false);
    } else if (displayName.length > 200) {
      errorNotify(Labels.nameValidation);
      setButton1Disable(false);
    } else if (!dateOfBirth) {
      errorNotify(Labels.DOBrequired);
      setButton1Disable(false);
    } else {
      const postData = {
        displayName: displayName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        emailid: emailid,
        phoneNo: phoneNo,
        city: city,
        countryid: countryid,
        stateid: stateid,
        address1: address1,
        language:localStorage.getItem("language")
      };
      const isBefore50YearsAgo = isDateBefore50YearsAgo(dateOfBirth);
      if (!isBefore50YearsAgo) {
        setButton1Disable(false);
        return errorNotify(Labels.DOBvalidation);
      }
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        setIsApiCalled(Math.random());
        setButton1Disable(false);
      } else {
        errorNotify(message);
        setButton1Disable(false);
      }
    }
  };

  //------------updating career profile---------------
  const getCareerProfile = (e) => {
    const value = e.target.value;
    setProfileData({ ...profileData, [e.target.name]: profanityfn(ProfanityList,value) });
  };

  const submitCareerProfile = async (e) => {
    setButton2Disable(true);
    const postcareerProfile = {
      designation: userDesignation,
      currentSalary: currentSalary,
      expectedSalary: expectedSalary,
      totalExperience: totalExperience,
      currentEmployer: currentEmployer,
      experienceLevel: userExperienceLevel,
      language:localStorage.getItem("language")
    };
    e.preventDefault();
    if ( !userDesignation || !currentSalary || !expectedSalary || !totalExperience ||!currentEmployer ) {
      setButton2Disable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (isNaN(currentSalary)) {
      setButton2Disable(false);
      return errorNotify(Labels.salaryValidation);
    } else if (currentSalary.length > 10) {
      setButton2Disable(false);
      return errorNotify(Labels.salaryCountValidation);
    } else if (isNaN(expectedSalary)) {
      setButton2Disable(false);
      return errorNotify(Labels.expectedSalaryValidation);
    } else if (isNaN(totalExperience)) {
      setButton2Disable(false);
      return errorNotify(Labels.experienceValidation);
    } else {
      const result = await axiosAPI.post( "/updateUserProfile", postcareerProfile);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        setIsApiCalled(Math.random());
        setButton2Disable(false);
      } else {
        warningNotify(message);
        setButton2Disable(false);
      }
    }
  };
  //-------------- Update Employment ---------------------
  const EditEmployment = (data) => {
    setUpdateEmployment(data);
    setemploymentUpdateModal(true);
  };
  //-------------- Update Education ---------------------
  const updateEducation = (data) => {
    setUpdatedEducation(data);
    setEducationUpdateModal(true);
  };
  //------------Delete Employment---------------
  const deleteEmployment = (e, data) => {
    e.stopPropagation();
    setDeleteEmploymentId(data.jseh_id);
    setEmploymentDeleteAlert(true);
  };

  //------------Delete Education---------------

  const deleteEducation = async (e, data) => {
    e.stopPropagation();
    setDeleteEducationId(data.jseb_id);
    setEmploymentDeleteAlert(true);
  };

  //-------------Delete Skills-----------------

  const deleteSkills = async (skillId) => {
    const postData = { skillid: skillId, language:localStorage.getItem("language")};
    const result = await axiosAPI.post("/deleteSkill", postData);
    const { success, message } = result.data;
    if (success === true) {
      succesNotify(message);
      setIsApiCalled(Math.random());
    } else {
      errorNotify(message);
    }
  };

  //-------------Delete preferred Locations-----------------

  const deletePreferredLocations = async (locationId) => {
    const postData = { locationid: locationId, language:localStorage.getItem("language")};
    const result = await axiosAPI.post("/deletePreferredLocation", postData);
    const { success, message } = result.data;
    if (success === true) {
      succesNotify(message);
      setIsApiCalled(Math.random());
    } else {
      errorNotify(message);
    }
  };
  //---------------Add Language Modal --------------------
  const [openLanguageAddModal, setLanguageAddModal] = useState(false);
  const LanguageAddModalClose = () => {
    setLanguageAddModal(false);
    setIsApiCalled(Math.random());
  };

  //-------------Delete Language-----------------

  const deleteLanguage = async (e, data) => {
    e.stopPropagation();
    setDeleteLanguageId(data.id);
    setEmploymentDeleteAlert(true);
  };

  //-------------Update Language-----------------
  const [openLanguageEditModal, setLanguageEditModel] = useState(false);
  const updateLanguage = (data) => {
    setLanguageData(data);
    setLanguageEditModel(true);
  };

  const closeLanguageEditModal = () => {
    setLanguageEditModel(false);
    setIsApiCalled(Math.random());
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <>
      {/* ------------------------Modals---------------------- */}
      <>
        {open === true ? (
          <EploymentModal open={open} handleClose={handleClose} />
        ) : null}
        {openEducationAddModel === true ? (
          <EducationModal
            open={openEducationAddModel}
            handleClose={handleCloseEducationModel}
          />
        ) : null}
        {skillsopen === true ? (
          <SkillsaddModal
            open={skillsopen}
            handleClose={skillCloseModal}
            skills={skillsId}
            setskills={setSkillsId}
          />
        ) : null}
        {openPreferedLocationsModal === true ? (
          <PreferedLocationModal
            open={openPreferedLocationsModal}
            handleClose={closePreferedLocationsModal}
            data={preferedLocations}
          />
        ) : null}
        {openCoverLetter === true ? (
          <CoverLetterModal
            open={openCoverLetter}
            handleClose={handleCloseCoverLetter}
            data={coverLetterData}
          />
        ) : null}
        {openPasswordReset === true ? (
          <EmployerPasswordChangeModal
            open={openPasswordReset}
            handleClose={setopenPasswordReset}
          />
        ) : null}
        {openEmploymentUpdateModal === true ? (
          <EmployementUpdateModal
            openEmploymentUpdateModal={openEmploymentUpdateModal}
            employemtUpdateModalClose={employemtUpdateModalClose}
            data={updateEmployment}
          />
        ) : null}
        {openEducationUpdateModal === true ? (
          <EducationUpdateModal
            open={openEducationUpdateModal}
            handleClose={educationUpdateModalClose}
            data={updatedEducation}
          />
        ) : null}

        {openEmploymentDeleteAlert ? (
          <EmploymentDeleteAlert
            open={openEmploymentDeleteAlert}
            handleClose={closeEmploymentDeleteModal}
            employmentId={deleteEmploymentId}
            educationId={deleteEducationId}
            LanguageId={deleteLanguageId}
          />
        ) : null}
        <LanguageAddModal
          open={openLanguageAddModal}
          handleClose={LanguageAddModalClose}
        />
        <LanguageEditModal
          open={openLanguageEditModal}
          handleClose={closeLanguageEditModal}
          languageData={languageData}
        />
      </>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          {/*--------------- Personal Information ---------------*/}
          <Paper elevation={0} sx={{ my: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
            >
              {Labels?.personalInformation}
            </Typography>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              {/*--------------- Name ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<PersonIcon />}
                  InputLabel={Labels?.name}
                  type="text"
                  label={Labels?.name}
                  name="displayName"
                  value={displayName}
                  onChange={(e) => onChangeUserProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- Email ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<EmailIcon />}
                  InputLabel={Labels?.emailAddress}
                  type="email"
                  label={Labels?.emailAddress}
                  name="emailid"
                  value={emailid}
                  readOnly
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- DOB ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<CalendarMonthIcon />}
                  InputLabel={Labels?.dateofBirth}
                  type="date"
                  label={Labels?.dateofBirth}
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={(e) => onChangeUserProfile(e)}
                  style={{ width: "100%" }}
                  inputProps={{
                    max: maxDateFormatted,
                  }}
                />
              )}
              {/*--------------- Gender ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <TransgenderIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.Gender}
                  </label>
                  <Gender
                    value={userGender}
                    setValue={setUserGender}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- Country ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <PublicIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.Country}
                  </label>
                  <Country
                    value={countryId}
                    setValue={setCountryId}
                    setStateId={setStateId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- State ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <BungalowIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.State}
                  </label>
                  <State
                    value={stateId}
                    setValue={setStateId}
                    country={countryId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- City ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<LocationOnIcon />}
                  InputLabel={Labels?.City}
                  label={Labels?.City}
                  name="city"
                  value={city}
                  onChange={(e) => onChangeUserProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- Address ---------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<HomeIcon />}
                  InputLabel={Labels?.Address}
                  label={Labels?.Address}
                  name="address1"
                  value={address1}
                  onChange={(e) => onChangeUserProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <CustomButton
                  onClick={sendChatProfanity}
                  label={Labels?.Save}
                  style={{ padding: "5px 25px" }}
                  disabled={isButton1Disable}
                />
              </div>
            </Box>
          </Paper>
          {/*--------------- Employment ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EmploymentHistory}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                 "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                 
                }}
                onClick={openEmploymentModel}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {employments &&
                    employments.map((val, index) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <Box
                          key={val.jseh_id}
                          sx={{
                            p: 1.5,
                            mb: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                          }}
                          onClick={() => EditEmployment(val)}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {val.designationName}
                            </Typography>
                            <Stack direction="row" alignItems="center">
                              {/* <DriveFileRenameOutlineOutlinedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.59)",
                                    mx: 1,
                                  }}
                                  onClick={() => EditEmployment(val)}
                                /> */}
                              <HighlightOffTwoToneIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteEmployment(e, val)}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.company}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.startyear} {val.startMonth} - {val.endyear}{" "}
                              {val.endMonth}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>
          {/*--------------- Education History ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EducationHistory}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                }}
                onClick={openEducationModel}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {educations &&
                    educations.map((val, index) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <Box
                          key={val.jseh_id}
                          sx={{
                            p: 1.5,
                            mb: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                          }}
                          onClick={() => updateEducation(val)}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {val.CourseName}
                            </Typography>
                            <Stack direction="row" alignItems="center">
                              <HighlightOffTwoToneIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteEducation(e, val)}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.institution.length > 25
                                ? val.institution.slice(0, 25) + "..."
                                : val.institution}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.startYear} to {val.endYear}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>
          {/*--------------- Languages ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.language}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                }}
                onClick={() => setLanguageAddModal(true)}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {Languages &&
                    Languages.map((val, index) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <Box
                          key={val.jseh_id}
                          sx={{
                            p: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            mb: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => updateLanguage(val)}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600, width: "0%" }}
                            >
                              {val.language}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              {/* <DriveFileRenameOutlineOutlinedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.59)",
                                    mx: 1,
                                  }}
                                  onClick={() => updateLanguage(val)}
                                /> */}
                              <HighlightOffTwoToneIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteLanguage(e, val)}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Stack direction="row">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Written}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {val?.written}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Spoken}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {val?.spoken}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={5}>
          {/*--------------- Enable Two-Factor Authentication ---------------*/}
          <Paper elevation={0} sx={{ my: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EnableTwoFactor}
              </Typography>
              <Switch
                checked={Ischecked}
                color="success"
                onChange={handleToggle}
              />
            </Stack>
          </Paper>
          {/*--------------- Career Profile ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.careerProfile}
              </Typography>
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              {/* ---------------Experience level--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <AssessmentIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.ExperienceLevel}
                  </label>
                  <ExperienceLevel
                    value={userExperienceLevel}
                    setValue={setUserExperienceLevel}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/* ---------------Designation--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <WorkIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.designation}
                  </label>
                  <DesignationProfile
                    value={userDesignation}
                    setValue={setUserDesignation}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/* ---------------Current Salary--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<CastForEducationIcon />}
                  InputLabel={Labels?.Currentsalary}
                  label={Labels?.Currentsalary}
                  type="text"
                  name="currentSalary"
                  value={currentSalary}
                  onChange={(e) => getCareerProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              {/* ---------------Expected Salary--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<WorkHistoryIcon />}
                  InputLabel={Labels?.ExpectedSalary}
                  label={Labels?.ExpectedSalary}
                  type="text"
                  name="expectedSalary"
                  value={expectedSalary}
                  onChange={(e) => getCareerProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              {/* ---------------Total Experience--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<PieChartIcon />}
                  InputLabel={Labels?.TotalExperience}
                  label={Labels?.TotalExperience}
                  type="text"
                  name="totalExperience"
                  value={totalExperience}
                  onChange={(e) => getCareerProfile(e)}
                  style={{ width: "100%" }}
                />
              )}
              {/* -------------Current Company----------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<ImportantDevicesIcon />}
                  InputLabel={Labels?.CurrentCompany}
                  label={Labels?.CurrentCompany}
                  type="text"
                  name="currentEmployer"
                  value={currentEmployer}
                  onChange={(e) => getCareerProfile(e)}
                  style={{ width: "100%" }}
                />
              )}

              <div style={{ display: "flex", justifyContent: "end" }}>
                <CustomButton
                  onClick={submitCareerProfile}
                  label={Labels?.Save}
                  style={{ padding: "5px 25px" }}
                  disabled={isButton2Disable}
                />
              </div>
            </Box>
          </Paper>
          {/*--------------- Key Skills ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.KeySkills}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                }}
                onClick={OpenSkillModal}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
                  {skillsName.map((val, index) => (
                    <Chip
                      key={index}
                      label={val.name}
                      onDelete={() => deleteSkills(val.id)}
                      sx={{ m: 1 }}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          </Paper>

          {/*--------------- Preferred Locations ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.PreferredLocations}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                }}
                onClick={() => setPreferedLocationsModal(true)}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5, overflow: "auto" }} className="jobseekerProfile">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
                  {preferedLocations.map((val, index) => (
                    <Chip
                      key={index}
                      label={
                        <span>
                          {val.city}, {val.state}, {val.country}
                        </span>
                      }
                      sx={{ m: 1 }}
                      onDelete={() => deletePreferredLocations(val.jspl_id)}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          </Paper>
          {/*--------------- Change Password ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.ChangePassword}
              </Typography>
              <DriveFileRenameOutlineOutlinedIcon
                sx={{
                  color: "rgba(113, 109, 109, 0.9)",
                  cursor: "pointer",
                }}
                onClick={openPasswordResetModal}
              />
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.75)" }}>
                It's a good idea to use a strong password that you're not using
                elsewhere
              </Typography>
            </Box>
          </Paper>
          {/*--------------- Profile Summary ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.profileSummary}
              </Typography>
              <DriveFileRenameOutlineOutlinedIcon
                sx={{
                  color: "rgba(113, 109, 109, 0.9)",
                  cursor: "pointer",
                }}
                onClick={openCoverLetterModel}
              />
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.75)" }}>
                {coverLetter && coverLetter.length > 150
                  ? coverLetter.slice(0, 150) + "..."
                  : coverLetter}
              </Typography>
            </Box>
          </Paper>
          {/*--------------- Resume ---------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#2B4947", p: 1.5 }}
            >
              {Labels?.Resume}
            </Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{ p: 1.5 }}
              className="jobseekerProfile"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  // border: "1px solid #1c614e73",
                  p: "5px",
                  borderRight: "none",
                }}
              >
                {/* <input
                        type="file"
                        accept=".pdf"
                        id="upload-file"
                        style={{ display: "block" }}
                        onChange={getResume}
                      /> */}
                {/* <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.75)" }}
                      >
                        Select File
                      </Typography> */}
                <IconButton onClick={handleAttachFile}>
                  <AttachFileIcon />
                </IconButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={getResume}
                />
                <input
                  disabled
                  type="name"
                  value={resume ? resume : Labels?.UploadYourResumeHere}
                  style={{ border: "none", margin: "0 10px" }}
                />
                {/* <DownloadForOfflineIcon
                        sx={{
                          color: "#1C614E",
                          cursor: "pointer",
                        }}
                        onClick={downloadResume}
                      /> */}
              </Stack>
              <CustomButton
                label={Labels?.Upload}
                style={{
                  borderRadius: "0px",
                  background: "white",
                  color: "#1C614E",
                  border: "1px solid #1c614e73",
                  padding: "5px",
                  boxShadow: "none",
                  fontWeight: "bold",
                  lineHeight: 1.7,
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                }}
                onClick={uploadResume}
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default JobseekerProfile;
