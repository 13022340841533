import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Dialog, Modal, Stack, Typography } from "@mui/material";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Skills from "../../CommonCode/Skills";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";

import { useSelector } from "react-redux";
import { useState } from "react";
import SkillsNew from "../../CommonCode/SkillsNew";
import SkillsEdit from "../../CommonCode/SkillsEditJob";

const SkillsaddModal = ({ open, handleClose, skills, setskills }) => {
  const [isButtonDisable, setButtonDisable] = useState(false);
  const postData = {
    skills: skills,
    language:localStorage.getItem("language")
  };
  const submitKeyskills = async () => {
    if (skills.length === 0) {
      return errorNotify(Labels.skillUpdation);
    } else {
      setButtonDisable(true);
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ width: "400px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1.5 }}
        >
          <Typography variant="h6" sx={{ color: "#1C614E" }}>
            {Labels?.KeySkills}
          </Typography>
          <HighlightOffIcon
            onClick={handleClose}
            sx={{ color: "#1C614E", cursor: "pointer" }}
          />
        </Stack>
        <Box sx={{ p: 1.5 }}>
          <SkillsNew value={skills} setValue={setskills} />
        </Box>
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 2,
              textTransform: "capitalize",
              color: "white",

              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            onClick={submitKeyskills}
            disabled={isButtonDisable}
          >
            {Labels?.Update}
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
};

export default SkillsaddModal;
