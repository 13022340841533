import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import {profanityfn}  from "../Profanity/Profanity";

const CounsultancyAddress = ({ value, setValue, countrycompany,style ,companyName}) => {
  const [States, setStates] = useState([]);
  const usertype = localStorage.getItem("userType");
  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getConsultancyAddress?countryid=${countrycompany?.id}&companyid=${companyName}
      `);
      const { status, data } = await result.data;
      if (status === true) {
        setStates(data);
      } else {
        setStates([]);
      }
    //   //// console.log(data);
    };
    getStates();
  
  }, [countrycompany,companyName]);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event, newValue) => {
    setValue(profanityfn(ProfanityList,newValue));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    // <FormControl sx={{ width: "100%" }}>
    //   <Select
    //     value={value}
    //     onChange={handleChange}
    //     placeholder={label}
    //     displayEmpty
    //     variant="outlined"
    //     sx={{
    //       padding: "10px 12px",
    //       fontFamily: "Poppins",
    //       ...style,
    //     }}
    //   >
    //     <MenuItem value="" disabled sx={{ color: "#c4c4c4" }}>
    //       <span style={{ color: "#bcbcbc" }}> {Labels?.SelectState}</span>
    //     </MenuItem>
    //     {States &&
    //       States.map((val, index) => {
    //         return (
    //           <MenuItem key={index} value={val.id}>
    //             {val.name}
    //           </MenuItem>
    //         );
    //       })}
    //   </Select>
    // </FormControl>
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={value}
        autoComplete={`off`}
        onChange={handleChange}
        options={States}
        sx={{ ...style }}
        readOnly={usertype === "Recruiter" ? true : false}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectState}/>
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default CounsultancyAddress;
