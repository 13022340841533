import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";


const initialState = {
  loading: false,
  error: "",
  companyDetails: {},
};

const getCompanyProfile = createAsyncThunk(
  "api/getCompanyProfile",
  async () => {
    try {
      const result = await axiosAPI.post("/getCompanyProfile");
      const { status, data, message } = result.data;
      if (status) {
        return data;
      } else {
        //// console.log(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  }
);

const getCompanyProfileSlice = createSlice({
  name: "companyProfileDetails",
  initialState,
  reducers: {
    updateCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
  },
  extraReducers: {
    [getCompanyProfile.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyProfile.fulfilled]: (state, { payload }) => {
      state.companyDetails = payload;
      state.loading = false;
    },
    [getCompanyProfile.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export { getCompanyProfile };

export default getCompanyProfileSlice.reducer;
