const routes = [
  "/jobseekerDashboard",
  "/jobseekerProfile",
  "/jobList",
  "/detailedJobView/:id",
  "/recommendedJobs",
  "/appliedJobs",
  "/similarJobs",
  "/Companydetailview/:id",
  "/createGroup",
  "/searchGroupList",
  "/myGroupList",
  "/groupDetail/:id",
  "/editGroup/:id",
  "/forum",
  "/myForum",
  "/createForum",
  "/forumDetail/:id",
  "/myForumDetail/:id",
  "/editForum/:id",
  "/chat",
  "/message",
  "/notifications",
];

const HeadingProvider = (val) => {
  if (val.includes("jobseekerDashboard")) {
    return "Dashboard";
  } else if (val.includes("jobseekerProfile")) {
    return "Profile";
  }  else if (val.includes("recommendedJobs")) {
    return "Recommended Jobs";
  }  else if (val.includes("appliedJobs")) {
    return "Applied Jobs";
  } else if (val.includes("jobList")) {
    return "All Jobs";
  } else {
    return "";
  }
};

export { HeadingProvider };
