import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";
import { errorNotify } from "../views/CommonCode/Commonfunc";

const initialState = {
  loading: false,
  error: "",
  list: {},
};

const getAllList = createAsyncThunk("api/getLists", async () => {
  try {
    const result = await axiosAPI.get("/getLists");
    const { success, data, message } = result.data;
    if (success) {
      return data;
    } else {
      errorNotify(message);
      return message;
    }
  } catch (error) {
    //// console.log(error.message);
  }
});

const createGetListSlice = createSlice({
  name: "getAllList",
  initialState,
  extraReducers: {
    [getAllList.pending]: (state) => {
      state.loading = true;
    },
    [getAllList.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.loading = false;
    },
    [getAllList.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export { getAllList };

export default createGetListSlice.reducer;
