import {
  Box,
  Divider,
  Avatar,
  Button,
  Grid,
  Chip,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import { Close, TokenSharp } from "@mui/icons-material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { axiosupload } from "../../Axios/Axios";
import React, { useEffect, useState, Fragment, createRef } from "react";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import CustomButton from "../../Components/CustomButton";
import TextfieldCustom from "../../Components/TextfieldCustom";
import JobCategory from "../../CommonCode/JobCategory";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import Skills from "../../CommonCode/Skills";
import SkillsEdit from "../../CommonCode/SkillsEditJob";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";

import {
  errorNotify,
  succesNotify,
  useStyles,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Gender from "../../CommonCode/Gender";
import MenuItem from "@mui/material/MenuItem";
import EmployerSideMenu from "../EmployerComponents/EmployerSideMenu";
import OfficePresence from "../../CommonCode/OfficePresence";
import { axiosAPI } from "../../Axios/Axios";
import EmployerNavbar from "../EmployerComponents/EmployerNavbar";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { format, addDays } from "date-fns";
import SelectJobType from "../../CommonCode/SelectJobType";
import Education from "../../CommonCode/Education";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import YesNo from "../../CommonCode/YesNo";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabel from "../../Components/InputWithLabel";
import AllLanguage from "../../CommonCode/AllLanguage";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import Weeks from "../../CommonCode/Weeks";
import JobCategoryEditJob from "../../CommonCode/JobcategoryEditJob";
import EducationEditJob from "../../CommonCode/EducationEditJob";
import { ElectricScooterSharp, Label } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import JobInfo from "../../CommonCode/JobInformation";
import WorkHour from "../../CommonCode/WorkingHour";
import Currency from "../../CommonCode/Currency";
import CompanyCountry from "../../CommonCode/CompanyCountries";
import JobPostingDuration from "../../CommonCode/JobDuration";
import SalaryPeriod from "../../CommonCode/SalaryPeriod";
import Percentage from "../../CommonCode/Percentage";
import CompanyBranchAddress from "../../CommonCode/CompanyBranchs";
import AddedCompanies from "../../CommonCode/AddedCompanies";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { profanityfn,profanitySend } from "../../Profanity/Profanity";
import { createNewLoginDetails } from "../../../redux/CreateLoginDetailSlice";
// import SalaryPeriod from" ../../CommonCode/SalaryPeriod";

export default function EditJob({
  value,
  api,
  setValue,
  placeHolder,
  selectedValue,
  style,
  newValue,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Labels = useSelector((state) => state.allLabels.labels);
  const Userdata = useSelector((state) => state.createLoginDetails);
  const classes = useStyles();
  const { id } = useParams();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [country, setcountry] = useState(null);
  const [state, setState] = useState(null);
  const [gender, setgender] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [qualification, setqualification] = useState({ id: "", name: "" });
  const [jobacate, setjobcate] = useState({
    id: "",
    categoryName: "",
    categoryIcon: "",
    jobs: "",
  });

  const [jobtype, setjobtype] = useState([]);
  const [jobRequiredLanguages, setjobRequiredLanguages] = useState([]);
  const [btntext, setbtntext] = useState(Labels.Add);
  const [editindex, seteditindex] = useState(null);

  const [FilterLanguage1, setFilterLanguage1] = useState("");

  const [jobRequiredLanguages1, setjobRequiredLanguages1] = useState([]);
  const [languageName1, setLanguageName1] = useState("1"); // English
  const [language1, setLanguage1] = useState(""); // id

  const [spoken, setLanguageReadLevel] = useState("");
  const [written, setLanguageWriteLevel] = useState("");
  const [languageName, setLanguageName] = useState("1"); // English
  const [Rlevel, setRLevel] = useState("");
  const [Wlevel, setWLevel] = useState("");
  const [skills, setskills] = useState([{ id: "", name: "", type: "" }]);

  const [language, setLanguage] = useState("");

  const [isRemote, setisRemote] = useState(false);
  const [remote, setRemote] = useState("International");
  const [isHybrid, setisHybrid] = useState(false);
  const [hybrid, setHybrid] = useState("National");

  const [radius, setRadius] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const [jobRequireTravel, setJobRequireTravel] = useState("");
  const [willingToRelocate, setRelocate] = useState("");

  const [receiveCoverLetter, setExpectCoverLetter] = useState("");
  const [showProfile, setShowProfile] = useState("");
  const [contactBymail, setShowEmail] = useState("");

  const [companyName, setcompayname] = useState("");

  const [jobinfo, setjobinfo] = useState([]);
  const [jobinformation, setjobinformation] = useState([]);
  const [AdressId, setAdressId] = useState(null);
  const [Adress, setAdress] = useState(null);
  const [salaryPeriod, setsalaryPeriod] = useState(null);
  const [officePercent, setofficePercent] = useState(null);
  const [CompanycountryID, setCompanycountryID] = useState(null);

  const [JobPostDuration, setJobPostDuration] = useState("");
  const [currency, setCurrency] = useState([]);

  // const [travel, setTravel] = useState([]);

  const [image, setImage] = useState("");
  const maxFileSize = 1 * 1024 * 1024;
  const [allCompanies, setAllCompanies] = useState([]);
  const [FilterLanguage, setFilterLanguage] = useState("");
  // const [Qualification2, setQualification2] = useState([]);
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  // const [travel, setTravel] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [Workinghour, setWorkinghour] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [typePercentage, settypepercentage] = useState(null);

  // For new client change - 14-06-24 - vis
  const [officePresence, setOfficePresence] = useState(8);
  const [officePercentage, setofficePercentage] = useState(100); // Old Value
  const [remotePercentage, setremotePercentage] = useState(0); // New Value
  const [countryValue, setcountryValue] = useState(1); // New Value
  const [continentValue, setcontinentValue] = useState(1); // New Value
  const [radiusValue, setradiusValue] = useState(0); // New Value
  const [stateID, setstateID] = useState(""); // New Value
  const [cityvalue, setcityvalue] = useState(""); // New Value
  // For new client change - 14-06-24 - vis

  const [logoPath, setlogoPath] = useState("");
  const employerTypeName = localStorage.getItem("employerType");
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const [errMsg, setErrMsg] = useState({
    spokenErr: false,
    writtenErr: false,
    languageErr: false,
  });
  const [errMsg1, setErrMsg1] = useState({
    languageErr1: false,
  });

  useEffect(() => {
    dispatch(createNewLoginDetails());
  }, []);

  const {
    jobName,
    description,
    noOfVacancies,
    applicationLastDate,
    city,
    travel,
    salary,
    benefits,
    experianceYears,
    whatYouWillDo,
    workingHours,
    companyLink,
    jobtitle,
    jobdesc,
    // typePercentage,
    certifications,
    additionalQualification,
  } = jobDetails;

  const currentDate = new Date();
  const maxDate = addDays(currentDate, 30);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (event) => {
    setJobPostDuration(profanityfn(ProfanityList, event.target.value));
  };

  // ---------Get Job Details---------
  const getjobdetails = async () => {
    setBackdrop(true);
    if (id) {
      const result = await axiosAPI.post(`/getJobInfo?&&jobid=${id}`);
      const { success, data, message } = result.data;
      if (success === true) {
        if (Userdata?.loginDetails?.uld_id == data?.employerid) {
          setBackdrop(false);
          setjobcate({
            id: data.jobCategory,
            categoryName: "",
            categoryIcon: "",
            jobs: 0,
          });

          setskills(
            data.jobSkills.map((skill) => ({
              id: skill.id,
              name: skill.skillName,
              type: "skill",
            }))
          );
          setjobinformation(data?.Jobinformations);
          setofficePercentage(data?.officePercentage);
          setremotePercentage(data?.remotePercentage);
          setcountryValue(data?.withinCountry);
          setcontinentValue(data?.withinContinents);
          setradiusValue(data?.jobRadious);
          setOfficePresence(data?.officePresence);
          setUserExperienceLevel(data.experianceYears);
          setJobDetails(data);
          setgender(data.genderId);
          setcountry(
            data.countryID ? { id: data.countryID, name: data.country } : null
          );
          setState(
            data.stateID ? { id: data.stateID, name: data.state } : null
          );
          setAdress({ name: data.companyAddress });

          // setqualification({ id: data.qualifications[0].educationID, name:data.qualifications[0].educationName })
          setqualification({
            id: data.qualifications[0]?.educationID,
            name: data.qualifications[0]?.educationName,
          });
          // setjobinfo({id:data?.id, })
          setjobtype(data.contract);
          settypepercentage(data.typePercentage);
          setjobRequiredLanguages(data?.languages);
          setjobRequiredLanguages1(data?.additionalLanguage);
          // setExpectCoverLetter(data.receiveCoverLetter)
          // setShowProfile(data.showProfile)
          // setShowEmail(data.contactBymail)

          setShowProfile(data ? data.showProfile : "");

          setExpectCoverLetter(data ? data.receiveCoverLetter : "");
          setShowEmail(data ? data.contactBymail : "");
          // setlogoPath(data.logoPath);
          // receiveCoverLetter 1
          // showProfile 1id?
          // contactBymail 1

          // contactBymail : "0"
          // receiveCoverLetter : '0'
          // showProfile : '0'

          // if (data.whatYouWillDo) {
          //   setjobinfo(data?.whatYouWillDo);
          // }
          // if (data.whoWeWouldLikeToHave) {
          //   setjobinfo(data?.whoWeWouldLikeToHave);
          // }
          // if (data.whoWeWouldLikeToHave) {
          //   setjobinfo(data?.whoWeWouldLikeToHave);
          // }
          // if (data.WouldBeAdvantageToHave) {
          //   setjobinfo(data?.WouldBeAdvantageToHave);
          // }se
          setlogoPath(data.companyLogo);
          setsalaryPeriod(data.salaryPeriod);
          setCurrency(data.currency);
          setJobPostDuration(
            data?.JobPostDuration ? data?.JobPostDuration : ""
          );
          setOfficePresence(data?.officePresence);
          setWorkinghour(data.workingDuration);
          setJobRequireTravel(
            data?.jobRequireTravel ? data?.jobRequireTravel : ""
          );
          setcompayname(data?.companyId);
          setRelocate(
            data?.willingToRelocate
              ? data?.willingToRelocate
              : data?.willingToRelocate === 0
              ? 0
              : ""
          );
        } else {
          setBackdrop(false);
          navigate("/employerJobList");
        }
      } else {
        setBackdrop(false);
        navigate("/employerJobList");
      }
    } else {
      setBackdrop(false);
      navigate("/employerJobList");
    }
  };

  useEffect(() => {
    getjobdetails();
  }, []);

  const Createjobads = (e) => {
    setJobDetails({
      ...jobDetails,
      [e.target.name]: profanityfn(ProfanityList, e.target.value),
    });
    setError(false);
  };
  const [viewSelect, setviewSelect] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);

  const handleItemPress = (selectedItem, index) => {
    setviewSelect(true);
    setbtntext(Labels.edit);
    seteditindex(index);
    setjobinfo({
      id: parseFloat(selectedItem.id),
      key: selectedItem.key,
      name: selectedItem?.name,
    });

    setJobDetails({ ...jobDetails, jobdesc: selectedItem.value });
  };

  useEffect(() => {
    // change item.name to item.namekey later after api change - vis
    if (officePercentage != null && remotePercentage != null) {
      if (
        officePercentage > 0 &&
        officePercentage < 100 &&
        remotePercentage > 0 &&
        remotePercentage < 100
      ) {
        setisHybrid(true);
        setisRemote(false);
        setOfficePresence(6);
        // Hybrid
      } else if (officePercentage == 0 && remotePercentage == 100) {
        setisHybrid(false);
        setisRemote(true);
        // Remote
        setOfficePresence(7);
      } else if (officePercentage == 100 && remotePercentage == 0) {
        setisHybrid(false);
        setisRemote(false);
        // Office
        setcountryValue(1);
        setcontinentValue(1);
        setradiusValue(0);
        setOfficePresence(8);
      }
    }
    if (officePercentage == "") {
      setremotePercentage("100");
      setisHybrid(false);
      setisRemote(true);
      setOfficePresence(7);
    }
    if (remotePercentage == "") {
      setofficePercentage("100");
      setisHybrid(false);
      setisRemote(false);
      setOfficePresence(8);
    }
  }, [officePercentage, remotePercentage, officePresence]);

  useEffect(() => {
    if (officePercentage == 100 && remotePercentage == 0) {
      setcountryValue(1);
      setcontinentValue(1);
    } else {
      if (continentValue == 0) {
        setcountryValue(0);
      }
    }
  }, [continentValue, countryValue]);

  const setradiusfn = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setradiusValue(value);
    }
  };

  const setofficePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setofficePercentage(inputValue);
        setremotePercentage((100 - inputValue).toString());
      }
    } else {
      setofficePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

  const setremotePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setremotePercentage(inputValue);
        setofficePercentage((100 - inputValue).toString());
      }
    } else {
      setremotePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

   const profanityChecker = ()=>{
    const postdata = {
      jobid: id,
      jobName: jobName,
      address: Adress.name,
      // description: description,
      jobCategory: jobacate.id.toString().replace(/(^,|,$|,,+)/g, ""),
      // noOfVacancies: noOfVacancies,
      workingHours: workingHours,
      experianceYears: experianceYears,
      countryID: country?.id,
      stateID: state?.id,
      city: city,
      language: "en",
      language1: "en",
      additionalLanguage: JSON.stringify(jobRequiredLanguages1),
      expectedLanguage: JSON.stringify(jobRequiredLanguages),
      // benefits: benefits,
      // gender: gender ? gender : "3",
      applicationLastDate: applicationLastDate,
      whatYouWillDo: whatYouWillDo,
      jobRequireTravel: jobRequireTravel,
      willingToRelocate: willingToRelocate,
      //  Needed values______________________________________________________________________________________________________

      officePresence: officePresence,
      officePercentage: officePercentage,
      remotePercentage: remotePercentage,
      withinCountry: countryValue,
      withinContinents: continentValue,
      jobRadious: radiusValue,
      stateID: stateID,
      city: cityvalue,
      //  Needed values______________________________________________________________________________________________________

      receiveCoverLetter: receiveCoverLetter,
      officePercentage: officePercentage !== null ? officePercentage : "",
      showYourProfile: showProfile,
      allowContactByMail: contactBymail,
      JobPostDuration: JobPostDuration,
      officePresence: officePresence,
      companyLink: companyLink,
      // workShift: workShift || "Day",
      companyId: companyName,
      type: "Consultancy",

      jobTypes: jobtype.toString().replace(/(^,|,$|,,+)/g, ""),
      jobSkills: skills
        .map((a) => a.id)
        .toString()
        .replace(/(^,|,$|,,+)/g, ""),
      certifications: certifications,
      // .id?.toString().replace(/(^,|,$|,,+)/g, ""),
      remote: "all",
      currency: currency,
      workingDuration: Workinghour,
      additionalQualification: additionalQualification,
      currency: currency,
      workingHours: workingHours,
      salaryPeriod: salaryPeriod,
      Jobinformations: JSON.stringify(jobinformation),
    };
    const postDataWithOfficePercentage = {
      ...postdata, // or postdata1 depending on your condition
      language: localStorage.getItem("language"),
    };


    let profanityDetected
    for (const key in postDataWithOfficePercentage) {
      if (profanitySend(ProfanityList, postDataWithOfficePercentage[key])) {
          profanityDetected = true;
          break;
      } else{
        profanityDetected = false;
      }
    }

    if(profanityDetected==false){
      submitFormData()
     }
     else{
     warningNotify(Labels.ProfanityLabel)
     }
   
  }

  const submitFormData = async (e) => {
    setButtonDisable(true);
    if (!jobName) {
      setButtonDisable(false);
      errorNotify(Labels.jobTitlerequired);
      return;
    } else if (jobName.length > 200) {
      setButtonDisable(false);
      errorNotify(Labels.jobTitleValidation);
      return;
    } else if (jobtype.length === 0) {
      setButtonDisable(false);
      errorNotify(Labels.jobTypeRequired);
      return;
    } else if (!jobacate || jobacate === "") {
      setButtonDisable(false);
      errorNotify(Labels.jobCategoryRequired);
      return;
    } else if (skills.length === 0) {
      setButtonDisable(false);
      errorNotify(Labels.skillsRequired);
      return;
    } else if (!country?.id) {
      errorNotify(Labels.countryRequired);
      setButtonDisable(false);
      return;
    } else if (!userExperienceLevel) {
      errorNotify(Labels.experienceValidation);
      setButtonDisable(false);
      return;
    }

    {
      const postdata = {
        jobid: id,
        jobName: jobName,
        address: Adress.name,
        // description: description,
        jobCategory: jobacate.id.toString().replace(/(^,|,$|,,+)/g, ""),
        // noOfVacancies: noOfVacancies,
        workingHours: workingHours,
        experianceYears: experianceYears,
        countryID: country?.id,
        stateID: state?.id,
        city: city,
        language: "en",
        language1: "en",
        additionalLanguage: JSON.stringify(jobRequiredLanguages1),
        expectedLanguage: JSON.stringify(jobRequiredLanguages),
        // benefits: benefits,
        // gender: gender ? gender : "3",
        applicationLastDate: applicationLastDate,
        whatYouWillDo: whatYouWillDo,
        jobRequireTravel: jobRequireTravel,
        willingToRelocate: willingToRelocate,
        //  Needed values______________________________________________________________________________________________________

        officePresence: officePresence,
        officePercentage: officePercentage,
        remotePercentage: remotePercentage,
        withinCountry: countryValue,
        withinContinents: continentValue,
        jobRadious: radiusValue,
        stateID: stateID,
        city: cityvalue,
        //  Needed values______________________________________________________________________________________________________

        receiveCoverLetter: receiveCoverLetter,
        officePercentage: officePercentage !== null ? officePercentage : "",
        showYourProfile: showProfile,
        allowContactByMail: contactBymail,
        JobPostDuration: JobPostDuration,
        officePresence: officePresence,
        companyLink: companyLink,
        // workShift: workShift || "Day",
        companyId: companyName,
        type: "Consultancy",

        jobTypes: jobtype.toString().replace(/(^,|,$|,,+)/g, ""),
        jobSkills: skills
          .map((a) => a.id)
          .toString()
          .replace(/(^,|,$|,,+)/g, ""),
        certifications: certifications,
        // .id?.toString().replace(/(^,|,$|,,+)/g, ""),
        remote: "all",
        currency: currency,
        workingDuration: Workinghour,
        additionalQualification: additionalQualification,
        currency: currency,
        workingHours: workingHours,
        salaryPeriod: salaryPeriod,
        Jobinformations: JSON.stringify(jobinformation),
      };
      const postDataWithOfficePercentage = {
        ...postdata, // or postdata1 depending on your condition
        language: localStorage.getItem("language"),
      };
      await axiosAPI
        .post("/updateJob", postDataWithOfficePercentage)
        .then((response) => {
          const { success, message, data } = response.data;
          if (success === true) {
            setButtonDisable(false);
            succesNotify(message);
            setTimeout(() => {
              navigate("/employerJobList");
              //// console.log(postdata, "postdata");
            }, 1000);
          } else {
            if (message === "Validation Error.") {
              setButtonDisable(false);
              errorNotify(Labels.allFieldsMandatory);
            } else {
              setButtonDisable(false);
              errorNotify(message);
            }
          }
        })
        .catch((error) => {
          setButtonDisable(false);
          //// console.log(error.message);
        });

    }
  };

  const setRemoteValue = (e) => {
    setRemote(e.target.value);
    if (e.target.value === "radius") {
      setRadius(0);
    } else {
      setRadius("");
    }
  };

  const setHybridValue = (e) => {
    setHybrid(e.target.value);
    if (e.target.value === "radius") {
      setRadius(0);
    } else {
      setRadius("");
    }
  };

  const Travelchange = (event) => {
    setJobRequireTravel(profanityfn(ProfanityList, event.target.value));
  };

  const addLanguage = () => {
    setTimeout(() => {
      setErrMsg({
        spokenErr: false,
        writtenErr: false,
        languageErr: false,
      });
    }, 2000);
    if (language) {
      // setTimeout(() => {
      //   setErrMsg({
      //     spokenErr: false,
      //     writtenErr: false,
      //     languageErr: false,
      //   });
      // }, 2000);
      if (!spoken) {
        return setErrMsg({ spokenErr: true });
      }
      if (!written) {
        return setErrMsg({ writtenErr: true });
      }
      const data = {
        written: Wlevel, // string w level name
        spoken: Rlevel, // string r level name
        language: languageName, // string

        languageid: language.l_id, // object (all data available)
        writtenid: written.id, // id of written
        spokenid: spoken.id, // id  of spoken
      };

      setjobRequiredLanguages((prevLanguages) => [...prevLanguages, data]);

      setLanguage("");
      setLanguageName("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
      setWLevel("");
      setRLevel("");
    } else {
      return setErrMsg({ languageErr: true });
    }
  };
  const removeAddedLanguage = (index) => {
    const updatedLanguages = [...jobRequiredLanguages];
    updatedLanguages.splice(index, 1);
    setjobRequiredLanguages(updatedLanguages);
  };

  const removeAddedJobInfo = (index) => {
    setviewSelect(false);
    setbtntext(Labels.Add);
    const updatedLanguages = [...jobinformation];
    updatedLanguages.splice(index, 1);
    setjobinformation(updatedLanguages);
  };

  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      <li>{file.path}</li>
      <img src={URL.createObjectURL(file)} alt={file.name} width="50px" />
    </div>
  ));

  // ----------------------------nicev to have language----------------------
  const addLanguage1 = () => {
    setTimeout(() => {
      setErrMsg1({
        languageErr1: false,
      });
    }, 2000);
    if (language1) {
      const data = {
        language1: languageName1, // string

        languageid1: language1.l_id, // object (all data available)
      };

      setjobRequiredLanguages1((prevLanguages1) => [...prevLanguages1, data]);
      setLanguage1("");
      setLanguageName1("");
    } else {
      return setErrMsg1({ languageErr1: true });
    }
  };
  const removeAddedLanguage1 = (index) => {
    const updatedLanguages1 = [...jobRequiredLanguages1];
    updatedLanguages1.splice(index, 1);
    setjobRequiredLanguages1(updatedLanguages1);
  };

  useEffect(() => {
    if (jobinformation?.length == 0) {
      setbtntext(Labels.Add);
      setJobDetails({ ...jobDetails, jobdesc: "" });
      setjobinfo("");
    }
  }, [jobinformation]);

  const [error, setError] = useState(false);

  const addinfo = () => {
    setbtntext(Labels.Add);
    if (jobinfo.length === 0 || jobdesc?.length === 0) {
      errorNotify(Labels.PleaseselectaJobInformation);
    } else {
      if (jobdesc !== undefined) {
        const data = {
          id: jobinfo.id,
          key: jobinfo.key,
          name: jobinfo.name,
          value: jobdesc,
        };

        if (editindex !== null) {
          const updatedLanguages = [...jobinformation];
          updatedLanguages.splice(editindex, 1, data);
          setjobinformation(updatedLanguages);
          seteditindex(null);
          setjobinfo("");
          setJobDetails({ ...jobDetails, jobdesc: "" });
          succesNotify(Labels.JobInformationEdited);
        } else {
          setjobinformation((prevInformation) => [...prevInformation, data]);
          setjobinfo("");
          setJobDetails({ ...jobDetails, jobdesc: "" });
          succesNotify(Labels.JobInformationAdded);
        }
      } else {
        errorNotify(Labels.PleaseselectaJobInformation);
      }
    }
  };

  const handleSelectedNameChange = (name) => {
    setSelectedName(name.name);
    if (name.id == 8) {
      setofficePercentage("100");
    }
    if (name.id == 7) {
      setofficePercentage("0");
    }
  };
  const [cropData, setCropData] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();
  const [openImage, setOpenImage] = useState(false);

  const onChange = (e) => {
    e.preventDefault();
    setOpenImage(false);
    setSelectImage(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files[0];
    }
    setImagename(files);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files);
  };
  const handleUpload = async () => {
    // if (typeof cropperRef.current?.cropper !== "undefined") {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    // setCropData(croppedDataURL);
    // setSelectImage(false);
    // uploadProfilePicture(croppedDataURL);
    uploadProfilePicture(croppedDataURL, imagename);
    // }
  };

  const uploadProfilePicture = async (manipulatedDataURL, imagename) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024;

    if (!allowedTypes.includes(blob.type)) {
      // throw new Error("Invalid file type selected");
      return errorNotify(Labels.invaildFile);
    }
    if (blob.size > maxFileSize) {
      // throw new Error("Please select an image smaller than 1MB.");
      return errorNotify(Labels.imageValidation);
    }
    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("language", localStorage.getItem("language"));

    try {
      const response = await axiosupload.post("/addjob", formData2, tokens);
      const { status, message } = response.data;
      if (status === "successs") {
        succesNotify(message);
        setSelectImage(false);
        setOpenImage(false);
        // getCompanyData();
        // setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.EditJob}
      </Typography>
      <Divider />
      <Box
        sx={{ p: 2, height: "73vh", overflow: "auto" }}
        className={classes.customColoredScrollbar}
      >
        <Grid container spacing={2}>
          <SimpleBackdrop open={backdropOpen} />
          {/* ---------------------------------basic details----------------------------   */}
          <Grid item xs={12} xl={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2} lg={2} mb={{ xs: 8, sm: 5 }} mt={-3}>
                <Box
                  sx={{ p: 1.5, maxHeight: "50px", mb: 1 }}
                  className="jobseekerProfile"
                >
                  {/* <Typography variant="subtitle1">{Labels?.Logo} </Typography> */}

                  <Grid item xs={12} lg={2}>
                    {/* <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 400, color: "#2B4947", p: 1.5 }}
                >
                  {"company Logo"}
                </Typography> */}

                    <Box sx={{ position: "relative", mt: 3, right: "15px" }}>
                      {logoPath?.endsWith("/") ? null : (
                        <Avatar
                          sx={{
                            width: 110,
                            height: 130,
                            borderRadius: 1,
                            cursor: "pointer",
                            marginRight: "25px", // Adjust margin as needed
                          }}
                          src={logoPath}
                          onClick={() => setOpenImage(true)}
                        />
                      )}
                      {/* <IconButton
                    component="label"
                    sx={{
                      position: "absolute",
                      top: "80%",
                      right: "10px", // Adjust this value as needed
                      transform: "translateY(-50%)",
                      padding: 0.5,
                      background: "white",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      "&:hover": {
                        background: "white",
                      },
                    }}
                  >
                    <CameraAltIcon
                      sx={{
                        color: "black",
                      }}
                    />
                    <InputBase
                      type="file"
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/gif"
                      onChange={onChange}
                    />
                  </IconButton> */}
                    </Box>
                    <Dialog
                      open={selectImage}
                      onClose={() => setSelectImage(false)}
                    >
                      <DialogTitle>
                        <Stack direction="row" justifyContent="flex-end">
                          <Close
                            sx={{ cursor: "pointer" }}
                            onClick={() => setSelectImage(false)}
                          />
                        </Stack>
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          sx={{
                            width: {
                              xs: "60%",
                              sm: "50%",
                              md: "60%",
                              lg: "80%",
                            },
                            height: {
                              xs: "60%",
                              sm: "50%",
                              md: "60%",
                              lg: "80%",
                            },
                          }}
                        >
                          <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                          />
                        </Box>
                      </DialogContent>
                      <DialogActions sx={{ padding: "25px" }}>
                        <CustomButton
                          onClick={handleUpload}
                          label={Labels.Upload}
                          style={{ padding: "5px 18px" }}
                        />
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} xl={10}>
                <Paper elevation={0} sx={{ my: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {Labels?.Basicdetails}
                    </Typography>
                  </Stack>
                </Paper>

                {/* ----------------------------------country------------------------- */}
                <Grid item xs={12} xl={12} my={2}>
                  <Box sx={{ m: "10px 5px" }}>
                    <label>
                      {Labels?.Country}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <CompanyCountry
                      value={country}
                      setValue={setcountry}
                      setAdressId={setAdress}
                      setAddressData={setAddressData}
                    />
                  </Box>
                </Grid>
              </Grid>
              {/* ---------------------Company NAme-------------------------- */}
              {employerTypeName == "Consultancy" ? (
                <Grid item xs={12} lg={12}>
                  <Box sx={{ m: "10px 5px" }}>
                    <label>{Labels?.companyName}</label>
                    <span style={{ color: "red" }}>*</span>
                    <AddedCompanies
                      value={companyName}
                      setValue={setcompayname}
                    />
                  </Box>
                </Grid>
              ) : null}
              {/* --------------------------------------Job titile--------------------------------- */}
              <Grid item xs={12} lg={12} mt={-1}>
                <InputWithLabel
                  InputLabel={Labels?.JobTitle}
                  label={Labels?.JobTitle}
                  name="jobName"
                  value={jobName}
                  required
                  style={{ width: "100%" }}
                  onChange={(e) => Createjobads(e)}
                />
              </Grid>
              {/* ------------------------------Adress------------------------------   */}
              <Grid item xs={12} lg={12}>
                <span style={{ fontSize: "14px" }}>{Labels?.Address}</span>
                <span style={{ color: "red" }}>*</span>
                <CompanyBranchAddress
                  value={Adress}
                  setValue={setAdress}
                  country={country}
                  setstateID={setstateID}
                  setcityvalue={setcityvalue}
                />
              </Grid>

              {/* --------------------- jobCategory --------------------------  */}
              <Grid item xs={12} lg={12}>
                <Box sx={{ m: "10px 5px" }}>
                  <label>
                    {Labels?.JobCategory}
                    <span style={{ color: "red" }}>* </span>
                  </label>
                  <JobCategory value={jobacate} setValue={setjobcate} />
                </Box>
              </Grid>

              {/* --------------------- Office presence % -------------------------- */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label>
                    <>
                      <span style={{ position: "relative", left: "10px" }}>
                        {" "}
                        {Labels?.InOffice}{" "}
                      </span>
                      <span
                        style={{
                          color: "red",
                          position: "relative",
                          left: "10px",
                        }}
                      >
                        *{" "}
                      </span>
                    </>
                  </label>
                  <InputWithLabel
                    // InputLabel={Labels.InOffice}
                    label={Labels.InOffice}
                    name="officePercentage"
                    value={officePercentage}
                    type="text"
                    setValue={setofficePercentage}
                    style={{
                      width: "100%",
                      position: "relative",
                      left: "10px",
                    }}
                    onChange={setofficePercentagefn}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>
                    <>
                      <span> {Labels?.InRemote} </span>
                      <span style={{ color: "red" }}>* </span>
                    </>
                  </label>
                  <InputWithLabel
                    // InputLabel={Labels.InRemote}
                    label={Labels.InRemote}
                    name="remotePercentage"
                    value={remotePercentage}
                    type="text"
                    setValue={setremotePercentage}
                    style={{ width: "100%" }}
                    onChange={setremotePercentagefn}
                  />
                </Grid>
              </Grid>

              {/* ------------------Hybrid for Office presence---------------------------- */}

              {(isHybrid || isRemote) && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: "5px auto",
                    position: "relative",
                    right: "4px",
                  }}
                >
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <label>
                        <>
                          <span> {Labels.Withinsamecountry} </span>
                        </>
                      </label>
                      <Select
                        sx={{ height: "40px" }}
                        value={countryValue}
                        onChange={(e) => {
                          setcountryValue(e.target.value);
                        }}
                      >
                        <MenuItem value={1}>{Labels.yes}</MenuItem>
                        <MenuItem value={0}>{Labels.No}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <label>
                        <>
                          <span> {Labels.WithinsameContinent} </span>
                        </>
                      </label>
                      <Select
                        sx={{ height: "40px" }}
                        value={continentValue}
                        onChange={(e) => {
                          setcontinentValue(e.target.value);
                        }}
                      >
                        <MenuItem value={1}>{Labels.yes}</MenuItem>
                        <MenuItem value={0}>{Labels.No}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <label style={{position:"relative", bottom:"1px"}}>
                      <>
                        <span> {Labels.Withinaradius} </span>
                      </>
                    </label>
                    <TextField
                      sx={{ height: "50px", position:"relative",
                        bottom:"3px" }}
                      inputProps={{
                        sx: {
                          height: "40px",
                          padding: `0px`,
                          paddingLeft: "10px",
                         
                        },
                      }}
                      onChange={setradiusfn}
                      value={radiusValue}
                      // id="outlined-required"
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} lg={12}>
                <Box sx={{ m: "10px 5px" }}>
                  <label>
                    {Labels.ContractType}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectJobType value={jobtype} setValue={setjobtype} />
                </Box>
              </Grid>
              {/* -----------------------contract type---------------------------------- */}

              <Grid item xs={12} lg={12}>
                {/* <InputWithLabel
                  InputLabel={"Full/Part time"}
                  label={"Contract Type %"}
                  name="typePercentage"
                  value={typePercentage}
                  type="number"
                  required
                  style={{ width: "100%" }}
                  onChange={(e) => Createjobads(e)}
                /> */}
                <label style={{ position: "relative", left: "2px" }}>
                  {" "}
                  {Labels.FullPartTime}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Percentage
                  value={typePercentage}
                  name="typePercentage"
                  setValue={settypepercentage}
                  sx={{
                    padding: "10px 12px",
                    fontSize: "14px", // Apply fontSize style here
                    fontFamily: "Poppins",
                    ...style,
                  }}
                />
              </Grid>
              {/* --------------------- Working hours per-------------------------- */}

              <Grid item xs={12} md={6} mt={1}>
              <span style={{ potition:"relative",left:"3px", fontSize: "14px", fontFamily: "Poppins" }}>
              {Labels.ExpectedHours}
                </span>
                <WorkHour
                  InputLabel={Labels.ExpectedHours}
                  label={Labels?.Workinghours}
                  name="WorkingDuration"
                  value={Workinghour}
                  setValue={setWorkinghour}
                />
              </Grid>
              {/* --------------------- hours -------------------------- */}
              <Grid item xs={12} md={6} mt={3}>
                <InputWithLabel
                  InputLabel={" "}
                  label={Labels?.Workinghours}
                  name="workingHours"
                  value={workingHours}
                  type="number"
                  inputProps={{ sx: { height: "19px"} }}
                  style={{ width: "100%", position:"relative",
                     bottom:"1px",}}                  onChange={(e) => Createjobads(e)}
                />
              </Grid>
              {/* -------------------------------Salary-------------------------------? */}

              <Grid
                item
                xs={12}
                md={12}
                justifyContent="space-between"
                direction="row"
                display="flex"
              >
                <InputWithLabel
                  InputLabel={
                    <>
                      <span style={{ position: "relative", bottom: "2px" }}>
                        {" "}
                        {Labels.Salary}{" "}
                      </span>
                      <span
                        style={{
                          color: "red",
                          position: "relative",
                          bottom: "2px",
                        }}
                      >
                        *{" "}
                      </span>{" "}
                    </>
                  }
                  label={Labels.Amount}
                  name="salary"
                  value={salary}
                  type="number"
                  style={{ width: "100%", mt: -0.6, position:"relative", right:"3px"}}
                  inputProps={{ sx: { height: "18px"} }}

                  onChange={(e) => Createjobads(e)}
                />

                <Currency
                  name="setCurrency"
                  value={currency}
                  setValue={setCurrency}
                  style={{ width: "98%" }}
                />

                <SalaryPeriod
                  style={{ width: "100%" }}
                  InputLabel={Labels.Period}
                  label={Labels?.Workinghours}
                  name="salaryPeriod"
                  value={salaryPeriod}
                  setValue={setsalaryPeriod}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <Box sx={{ m: "10px 5px", my: 1.5 }}>
                  <label>
                    {Labels?.ClosingDate}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ paddingTop: 0 }}
                    >
                      <DatePicker
                        fullWidth
                        name="closingdate"
                        onChange={(date) => setClosingDate(date)}
                        shouldDisableDate={shouldDisableDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
          {/* -------------------- Yes / No Questions -------------------- */}
          <Grid item xs={12} xl={6}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels?.Miscellaneous}
                </Typography>
              </Stack>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} my={1}>
                <Box>
                  <label>{Labels?.Wouldyouliketoshowyourprofile}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={showProfile}
                    setValue={setShowProfile}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <label>{Labels?.Canpeoplecontactyoubymail}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={contactBymail}
                    setValue={setShowEmail}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} my={1}>
                <Box>
                  <label>{Labels?.DoyouexpecttoreceiveaCoverletter}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={receiveCoverLetter}
                    setValue={setExpectCoverLetter}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} my={1}>
                <Box>
                  <label>{Labels.JobPostDuration}</label>
                  <JobPostingDuration
                    placeholder={Labels.JobPostDuration}
                    value={JobPostDuration}
                    setValue={setJobPostDuration}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* </Grid> */}
            <Grid item xs={12} xl={12}>
              <Paper elevation={0} sx={{ my: 3.5 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    p: 1.5,
                    background: "#DEF0EA",
                    borderRadius: "5px",
                    border: "1 px solid #ecf4f1",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                  >
                    {Labels.JobRequirementsExpectationsInfo}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            {/* ---------------------Added Languages -------------------------- */}
            <Grid item xs={12} xl={12} mb={2}>
              {jobRequiredLanguages.length !== 0 && (
                // <Grid  container spacing={2}direction="row" item xs={12 } >
                <Grid container spacing={1}>
                  {/* <Stack spacing={2}direction="row" sx={{ flexWrap: 'wrap' }}> */}
                  {jobRequiredLanguages.map((data, index) => (
                    <Grid item xs={8} md={4} lg={4} key={index} direction="row">
                      {/* <Chip
                        key={index}
                       
                        label={`${data?.languageName}:  ${data?.Wlevel},  ${data?.Rlevel}`}


                        onDelete={() => removeAddedLanguage(index)}
                        sx={{ mr: 1, width:"100%" }}
                      /> */}
                      <Box
                        key={index}
                        sx={{
                          p: 2,
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          mb: 2,
                          cursor: "pointer",
                          xs: "100%",
                          width: {
                            xs: "100%", // Set width to 100% on xs screens
                            sm: "80%", // Set width to 80% on sm and larger screens
                          },
                        }}
                        // onClick={() => updateLanguage(val)}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 600, width: "0%" }}
                          >
                            {/* {`${data?.languageName}`}  */}
                            {`${data?.language}`}
                          </Typography>

                          <Stack direction="row" alignItems="center">
                            <HighlightOffTwoToneIcon
                              sx={{
                                cursor: "pointer",
                                fontsize: "1.2rem",
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                              onClick={() => removeAddedLanguage(index)}
                            />
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack direction="row">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {Labels?.Written}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                                mx: 2,
                                width: "200px",
                              }}
                            >
                              {/* {data?.Wlevel} */}
                              {`${data?.written}`}
                            </Typography>
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {Labels?.Spoken}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                                mx: 2,
                                width: "200px",
                              }}
                            >
                              {/* {data?.Rlevel} */}
                              {`${data?.spoken}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                  ))}
                  {/* </Stack> */}
                </Grid>
              )}

              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "block", md: "space-between" }}
                alignItems={{ xs: "center", md: "end" }}
              >
                <Box sx={{ m: "10px 5px", width: "100%" }}>
                  <label>{Labels?.ExpectedLanguages}</label>
                  <span style={{ color: "red" }}>*</span>
                  <AllLanguage
                    languageName={languageName} // english
                    setLanguageName={setLanguageName}
                    value={language} // id ?
                    setValue={setLanguage}
                    label={Labels?.language}
                    setFilterLanguage={setFilterLanguage}
                    jobreqlang={jobRequiredLanguages}

                  />
                </Box>

                <Box sx={{ m: "10px 5px", width: "100%" }}>
                  <label>{Labels?.Spoken} </label>
                  <LanguageLevel
                    value={spoken}
                    setValue={setLanguageReadLevel}
                    setLevel={setRLevel}
                    label={Labels?.Spoken}
                  />
                  <br />
                </Box>

                <Box sx={{ m: "10px 5px", width: "100%" }}>
                  <label>{Labels?.Written}</label>
                  <LanguageLevel
                    value={written}
                    setValue={setLanguageWriteLevel}
                    setLevel={setWLevel}
                    label={Labels?.Written}
                  />
                </Box>

                <Box sx={{ m: "10px 5px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "rgba(109, 160, 156, 0.15)",
                      color: "rgb(43, 73, 71)",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "rgba(109, 160, 156, 0.15)",
                      },
                      "&:active": {
                        backgroundColor: "#1C614E",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "#1C614E",
                      },
                      boxShadow: "none",
                    }}
                    onClick={() => addLanguage()}
                  >
                    {Labels?.Add}
                  </Button>
                </Box>
              </Stack>
              {errMsg?.languageErr && (
                <span style={{ color: "#ff0000", fontSize: "13px" }}>
                  {Labels?.languagefieldisrequird}
                </span>
              )}
              {errMsg?.writtenErr && (
                <span style={{ color: "#ff0000", fontSize: "13px" }}>
                  {Labels?.Writtenfieldisrequird}
                </span>
              )}
              {errMsg?.spokenErr && (
                <span style={{ color: "#ff0000", fontSize: "13px" }}>
                  {Labels?.Spokenfieldisrequird}
                </span>
              )}
            </Grid>
            {/* ---------------------nice to have Languages -------------------------- */}
            <Grid item xs={12} xl={12}>
              {jobRequiredLanguages1
                ? jobRequiredLanguages1.length !== 0 && (
                    // <Grid  container spacing={2}direction="row" item xs={12 } >
                    <Grid container spacing={1}>
                      {/* <Stack spacing={2}direction="row" sx={{ flexWrap: 'wrap' }}> */}
                      {jobRequiredLanguages1.map((data, index) => (
                        <Grid
                          item
                          xs={9}
                          md={4}
                          lg={4}
                          key={index}
                          direction="row"
                        >
                          {/* <Chip
                        key={index}
                       
                        label={`${data?.languageName}:  ${data?.Wlevel},  ${data?.Rlevel}`}


                        onDelete={() => removeAddedLanguage(index)}
                        sx={{ mr: 1, width:"100%" }}
                      /> */}
                          <Box
                            key={index}
                            sx={{
                              p: 2,
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              mb: 2,
                              cursor: "pointer",
                              xs: "100%",
                              width: {
                                xs: "100%", // Set width to 100% on xs screens
                                sm: "80%", // Set width to 80% on sm and larger screens
                              },
                            }}
                            // onClick={() => updateLanguage(val)}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600, width: "0%" }}
                              >
                                {/* {`${data?.languageName}`}  */}
                                {`${data?.language1}`}
                              </Typography>

                              <Stack direction="row" alignItems="center">
                                <HighlightOffTwoToneIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.59)",
                                  }}
                                  onClick={() => removeAddedLanguage1(index)}
                                />
                              </Stack>
                            </Stack>
                          </Box>
                        </Grid>
                      ))}
                      {/* </Stack> */}
                    </Grid>
                  )
                : null}

              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "block", md: "space-between" }}
                alignItems={{ xs: "center", md: "end" }}
              >
                <Box sx={{ m: "10px 5px", width: "100%" }}>
                  <label>{Labels?.Nicetohave}</label>
                  <AllLanguage
                    languageName={languageName1} // english
                    setLanguageName={setLanguageName1}
                    value={language1} // id ?
                    setValue={setLanguage1}
                    label={Labels?.language}
                    setFilterLanguage={setFilterLanguage1}
                    jobRequiredLanguages1={jobRequiredLanguages1}
                  />
                </Box>

                <Box sx={{ m: "10px 5px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "rgba(109, 160, 156, 0.15)",
                      color: "rgb(43, 73, 71)",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "rgba(109, 160, 156, 0.15)",
                      },
                      "&:active": {
                        backgroundColor: "#1C614E",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "#1C614E",
                      },
                      boxShadow: "none",
                    }}
                    onClick={() => addLanguage1()}
                  >
                    {Labels?.Add}
                  </Button>
                </Box>
              </Stack>
              {errMsg?.languageErr && (
                <span style={{ color: "#ff0000", fontSize: "13px" }}>
                  {Labels?.languagefieldisrequird}
                </span>
              )}
            </Grid>
            <Grid container spacing={1}>
              {/* ------------------------------Seniority level--------------------------------- */}
              <Grid item xs={12} lg={12}>
                {/* <InputWithLabel
                  InputLabel={"Seniority level"}
                  label={Labels?.Experience}
                  name="experience"
                  value={experience}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  style={{ width: "100%" }}
                  onChange={(e) => Createjobads(e)}
                /> */}
                <Box sx={{ m: "10px 5px" }}>
                  <label>{Labels?.ExperienceLevel}</label>
                  <span style={{ color: "red" }}>*</span>
                  <ExperienceLevel
                    value={userExperienceLevel}
                    setValue={setUserExperienceLevel}
                    style={{
                      background: "white",
                    }}
                  />
                </Box>
              </Grid>
              {/* --------------------- Qualifications -------------------------- */}
              <Grid item xs={12} md={12}>
                {/* <Box sx={{ m: "10px 5px" }}>
                  <label>{"Education/Certifications"}</label>
                  <EducationEditJob
                    value={qualification}
                    setValue={setqualification}
                  />
                </Box> */}
                <InputWithLabel
                  InputLabel={Labels.EducationCertifications}
                  label={Labels?.Nicetohave}
                  name="certifications"
                  value={certifications}
                  // setValue={setQualification2}
                  style={{ width: "100%" }}
                  onChange={(e) => Createjobads(e)}
                />
              </Grid>
              {/* -------------------------Nice to have---------------------------------- */}
              <Grid item xs={12}>
                <InputWithLabel
                  InputLabel={Labels?.Nicetohave}
                  label={Labels?.Nicetohave}
                  name="additionalQualification"
                  value={additionalQualification}
                  // setValue={setQualification2}
                  style={{ width: "100%" }}
                  onChange={(e) => Createjobads(e)}
                />
              </Grid>
              {/* -----------------------------travel---------------------------------------- */}
              <Grid item xs={12} md={12}>
                <Box sx={{ m: "5px" }}>
                  <label>{Labels?.DoestheJobrequiretravelling}</label>
                  <Fragment>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        onChange={Travelchange}
                        value={jobRequireTravel}
                        // setValue={setJobRequireTravel}
                        fullWidth
                        displayEmpty
                        variant="outlined"
                        sx={{
                          padding: "10px 12px",
                          fontFamily: "Poppins",
                          ...style,
                        }}
                      >
                        <MenuItem disabled value="">
                          <span
                            style={{ color: "#bcbcbc" }}
                          >{`${Labels?.yes}/${Labels?.No}`}</span>
                        </MenuItem>
                        <MenuItem value="1">{Labels?.yes}</MenuItem>
                        <MenuItem value="0">{Labels?.No}</MenuItem>
                      </Select>
                    </FormControl>
                  </Fragment>
                </Box>
              </Grid>
              {/* --------------------- skils -------------------------- */}
              <Grid item xs={12} md={12}>
                <Box sx={{ m: "10px 5px", my: 1.5 }}>
                  <label>
                    {Labels.ExpectedSkills}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  {/* <Skills value={value} setValue={setskills} /> */}
                  <SkillsEdit value={skills} setValue={setskills} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={12}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels.JobOverview}
                </Typography>
              </Stack>
            </Paper>
            {jobinformation.length !== 0 && (
              // <Grid  container spacing={2}direction="row" item xs={12 } >
              <Grid container spacing={1}>
                {/* <Stack spacing={2}direction="row" sx={{ flexWrap: 'wrap' }}> */}
                {jobinformation.map((data, index) => (
                  <Grid
                    item
                    xs={10}
                    md={6}
                    lg={4}
                    key={data.id}
                    direction="row"
                  >
                    {/* <Chip
                        key={index}
                       
                        label={`${data?.languageName}:  ${data?.Wlevel},  ${data?.Rlevel}`}


                        onDelete={() => removeAddedLanguage(index)}
                        sx={{ mr: 1, width:"100%" }}
                      /> */}
                    <Box
                      sx={{
                        p: 2,
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        mb: 2,
                        cursor: "pointer",
                        xs: "100%",
                        width: {
                          xs: "100%", // Set width to 100% on xs screens
                          sm: "80%", // Set width to 80% on sm and larger screens
                        },
                      }}
                      // onClick={() => updateLanguage(val)}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        {/* <Typography
                              variant="subtitle2"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              Info 
                            </Typography> */}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 600 }}
                        >
                          {/* {`${data?.languageName}`}  */}
                          {`${data?.name}`}
                        </Typography>

                        <Stack direction="row" alignItems="center">
                          <EditIcon
                            sx={{
                              cursor: "pointer",
                              fontsize: "1.2rem",
                              color: "rgba(0, 0, 0, 0.59)",
                            }}
                            onClick={() => handleItemPress(data, index)}
                          />
                          <HighlightOffTwoToneIcon
                            sx={{
                              cursor: "pointer",
                              fontsize: "1.2rem",
                              color: "rgba(0, 0, 0, 0.59)",
                            }}
                            onClick={() => removeAddedJobInfo(index)}
                          />
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack direction="row">
                          {/* <Typography
                              variant="subtitle2"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              Value 
                            </Typography> */}
                          <Typography
                            variant="caption"
                            sx={{
                              color: "rgba(0, 0, 0, 0.59)",
                              mx: 2,
                              // width: "300px",
                            }}
                          >
                            {/* {data?.Wlevel} */}
                            {data?.value?.length > 150
                              ? data?.value.slice(0, 150) + "...."
                              : data?.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
                {/* </Stack> */}
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <JobInfo
                  value={jobinfo}
                  label={Labels?.jobDescription}
                  placeholder={Labels.SelectJobInformation}
                  setValue={setjobinfo}
                  style={{ width: "100%" }}
                  setviewSelect={setviewSelect}
                  viewSelect={viewSelect}
                />
              </Grid>
              <Grid item xs={1} mt={2}>
                <CustomButton
                  label={btntext}
                  style={{ padding: "5px 25px" }}
                  onClick={addinfo}
                  disabled={isButtonDisable}
                />
              </Grid>
              {/* {jobinformation.map((data, index) => (
              <div key={index}>
                <p>{`Job Information: ${data.jobinfo}`}</p>
                <p>{`Job Description: ${data.jobdesc}`}</p>
              </div>
            ))} */}
            </Grid>

            <Grid item xs={12}>
              {error && (
                <p style={{ color: "red" }}>{Labels.Pleasegiveadescription}</p>
              )}
              <InputWithLabel
                // InputLabel={Labels?.jobDescription}
                label={Labels?.jobDescription}
                multiline
                rows={4.6}
                // required
                name="jobdesc"
                value={jobdesc}
                style={{ width: "100%", mt: 1 }}
                onChange={(e) => Createjobads(e)}
              />
            </Grid>
          </Grid>
          {/* --------------------- Submit Button -------------------------- */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px auto",
            }}
          >
            <CustomButton
              label={Labels?.EditJob}
              style={{ padding: "5px 25px" }}
              onClick={profanityChecker}
              disabled={isButtonDisable}
            />
          </div>
        </Grid>
      </Box>
    </Paper>
  );
}
