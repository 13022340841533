import { Box, Dialog, Stack, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/joy";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import TextfieldCustom from "../../Components/TextfieldCustom";
import Qualification from "../../CommonCode/Qualification";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function ({ open, handleClose, data }) {
  const Labels = useSelector((state) => state.allLabels.labels);
  const [educationId, setEducationId] = useState(data.educationID);
  const [institution, setInstitution] = useState(data.institution);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    const startYear = data.startYear;
    const endYear = data.endYear;
    setStartDate(`${startYear}-01-01`);
    setEndDate(`${endYear}-12-31`);
  }, []);

  const updateEducation = async (e) => {
    e.preventDefault();
    //// console.log(educationId ,institution,startDate,endDate);
    if (!educationId || !institution || !startDate || !endDate) {
      return errorNotify(Labels.allFieldsMandatory);
    } else if (institution.length > 200) {
      errorNotify(Labels.institutionNameValidation);
    } else {
      const startdate = new Date(startDate);
      const enddate = new Date(endDate);
      const startYear = startdate.getFullYear().toString();
      const endYear = enddate.getFullYear().toString();
      const startMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(startdate);
      const endMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(enddate);
      const postData = {
        educationid: data.jseb_id,
        educationID: educationId,
        startYear: startYear,
        endYear: endYear,
        startMonth: startMonth,
        endMonth: endMonth,
        institution: institution,
      };
      const result = await axiosAPI.post("/updateEducation", postData);
      const { success } = result.data;
      if (success === true) {
        succesNotify(Labels.educationUpdateSuccess);
        handleClose();
      } else {
        errorNotify(Labels.somethingWrong);
      }
    }
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
        {Labels?.Education}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#1C614E",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 2 }}>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.Qualifications}
          </Typography>
          <Qualification
            value={educationId}
            setValue={setEducationId}
            style={{
              width: "100%",
            }}
          />
        </div>

        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.Institution}
          </Typography>
          <TextfieldCustom
            style={{
              fontFamily: "Poppins",
              width: "100%",
            }}
            name="institution"
            value={institution}
            onChange={(e) => setInstitution(profanityfn(ProfanityList,e.target.value))}
          />
        </div>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={startDate}
              onChange={(event) => setStartDate(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                max: today,
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={endDate}
              onChange={(event) => setEndDate(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                max: today,
                min: startDate,
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(e) => updateEducation(e)}
          >
          {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
