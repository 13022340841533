import {
  Stack,
  Table,
  Chip,
  Card,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Button,
  Skeleton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
// import { useNavigate } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import CreateCompany from "./createCompany";

import CustomButton from "../../Components/CustomButton";
import DeleteCompany from "./deleteComapny.";
import { warningNotify } from "../../CommonCode/Commonfunc";

// import DeleteUser from "./deleteUser";

function CompanyList() {
  const Labels = useSelector((state) => state.allLabels.labels);
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  // const redirect = useNavigate();
  const [createCompany, setcreateCompany] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [listCompanies, setListCompanies] = useState([]);
  const [deleteCompany, setDeleteCompany] = useState(false);
  const [companyid, setCompanyId] = useState("");
  const [counter, setCounter] = useState(1);
  const [loading, setloading] = useState(true);
  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  const deleteCompanyById = (id) => {
    setDeleteCompany(true);
    setCompanyId(id);
  };

  const getAllCompanies = async () => {
    try {
      const res = await axiosAPI.post("/companiesList");
      const { data, status } = res.data;
      if (status) {
        setListCompanies(data);
        setloading(false);
      } else {
        setloading(false);
        setListCompanies([]);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CreateCompany
          open={createCompany}
          handleClose={() => setcreateCompany(false)}
          getAllCompanies={getAllCompanies}
        />
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.Companies}
        </Typography>
        <CustomButton
          onClick={() => setcreateCompany(true)}
          label={Labels?.CreateCompany}
        />
      </Stack>
      <TableContainer sx={{ my: 2 }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                borderTop: "10px solid #EAEFF1",
                borderBottom: "10px solid #EAEFF1",
                background: "rgba(3, 132, 97, 0.3)",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.sno}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.CompanyInfo}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels.Website}</TableCell>

              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.BusinessIndustry}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.PostedJobs}{" "}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.actions} </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <>
                {Array(5)
                  .fill()
                  .map(() => {
                    return (
                      <TableRow
                        sx={{
                          borderTop: "10px solid #EAEFF1",
                          borderBottom: "10px solid #EAEFF1",
                          background: "#fff",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope={isSmallScreen ? "column" : "row"}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={23}
                            style={{ margin: "2px auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}{" "}
              </>
            ) : (
              listCompanies
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((Companies, index) => (
                  <TableRow
                    sx={{
                      borderTop: "10px solid #EAEFF1",
                      borderBottom: "10px solid #EAEFF1",
                      background: "#fff",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope={isSmallScreen ? "column" : "row"}
                    >
                      {index + 1}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope={isSmallScreen ? "column" : "row"}
                    >
                      {Companies.name.length > 10 ? (
                        <>
                          {Companies.name.slice(0, 10)}
                          <br />
                          {Companies.name.slice(10)}
                        </>
                      ) : (
                        Companies.name
                      )}
                    </TableCell>
                    <TableCell>{Companies.website}</TableCell>
                    <TableCell>
                      <Chip
                        key={""}
                        label={Companies.businessCategory}
                        size="small"
                        sx={{
                          color: "#BA8C04",
                          background: "#BA8C040F",
                          borderRadius: 1,
                          alignItems: "center",
                          minWidth: 120,
                          margin: "10px 10px 10px 0",
                          border: "1px solid rgba(0, 0, 0, 0.10)",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope={isSmallScreen ? "column" : "row"}
                    >
                      {Companies.jobCount}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope={isSmallScreen ? "column" : "row"}
                    >
                      {Companies.jobCount == 0 && (
                        <DeleteIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "#d32f2f",
                          }}
                          onClick={() =>
                            deleteCompanyById(Companies.consultancy_id)
                          }
                        />
                      )}

                      {Companies.jobCount != 0 && (
                        <DeleteForeverIcon
                          onClick={(e) =>
                            warningNotify(Labels.Cannotdeletethiscompany)
                          }
                          sx={{
                            width: 28,
                            height: 28,
                            color: "#d32f2f",
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={listCompanies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
      <DeleteCompany
        open={deleteCompany}
        handleClose={() => setDeleteCompany(false)}
        companyid={companyid}
        getAllCompanies={getAllCompanies}
      />
    </ThemeProvider>
  );
}

export default CompanyList;
