import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";
import { errorNotify } from "../views/CommonCode/Commonfunc";

const initialState = {
  loading: false,
  error: "",
  jobseekerDetails: {},
};

const createJobseekerDetails = createAsyncThunk("api/profile", async () => {
  try {
    const uid = localStorage.getItem("uid");
    const postdata = {
      jobseekerid: uid,
    };
    const result = await axiosAPI.post("/profile", postdata);
   
    const { success, data, message } = result.data;
    // // console.log(result);
    if (success) {
      return data;
    } else {
      errorNotify(message);
    }
  } catch (error) {
    //// console.log(error.message);
  }
});

const createJobseekerDetailsSlice = createSlice({
  name: "createJobseekerDetails",
  initialState,
  reducers: {
    updateJobSeekerDetails: (state, action) => {
      state.jobseekerDetails = action.payload;
    },
  },
  extraReducers: {
    [createJobseekerDetails.pending]: (state) => {
      state.loading = true;
    },
    [createJobseekerDetails.fulfilled]: (state, { payload }) => {
      state.jobseekerDetails = payload;
      state.loading = false;
    },
    [createJobseekerDetails.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export const { updateJobSeekerDetails } = createJobseekerDetailsSlice.actions;

export { createJobseekerDetails };

export default createJobseekerDetailsSlice.reducer;
