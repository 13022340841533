import React, { useEffect, useState } from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import ReplayMessageModal from "./ReplayMessageModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateIcon from "@mui/icons-material/Create";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from '@mui/icons-material/Send';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "left",
  color: "black",
  boxShadow: "none",
}));

function EmployerMessages() {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isApiCall, setIsApiCall] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  //  ------------------get inbox message list--------------
  const [messageList, setMessageList] = useState([]);
  const getInboxList = async () => {
    const result = await axiosAPI.get("/inboxMessageList");
    const { success, data } = result.data;
    if (success) {
      setMessageList(data);
    }
  };
  // ------------------get send message list--------------
  const [sentMessageList, setSentMessageList] = useState([]);
  const [loading, setloading] = useState(true);
  const getSentMessageList = async () => {
    const result = await axiosAPI.get("/sentMessageList");
    const { success, data } = result.data;
    if (success) {
      setSentMessageList(data);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  //---------------sent inbox-message replay--------------
  const [openReplayModal, setReplayModal] = useState(false);
  const [messageData, setMessageData] = useState({});
  const closeReplayModal = () => {
    setReplayModal(false);
  };

  const replayMesssage = (e, data) => {
    e.stopPropagation();
    setMessageData(data);
    setReplayModal(true);
  };

  const messageToAdmin = (e) => {
    const data = {
      toUser: "1",
      toUserName: " Admin",
    };
    setMessageData(data);
    setReplayModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSentMessageList();
    getInboxList();
  }, [isApiCall]);

  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <Paper elevation={0} sx={{ my: 2 }}>
      <ReplayMessageModal
        open={openReplayModal}
        handleClose={setReplayModal}
        data={messageData}
        setIsApiCall={setIsApiCall}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <List component="nav" sx={{ m: 1 }}>
            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background:
                  selectedIndex === 1 ? "#6DA09C1F !important" : "transparent",
              }}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Inbox} />
            </ListItemButton>
            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background:
                  selectedIndex === 0 ? "#6DA09C1F !important" : "transparent",
              }}
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Message} />
            </ListItemButton>

            <ListItemButton
              sx={{
                border: "1px solid #F0E5E5",
                borderRadius: 1,
                my: 1,
                "&:hover": {
                  background: "#6DA09C1F",
                },

                background: "transparent",
              }}
              selected={selectedIndex === 2}
              onClick={(event) => messageToAdmin(event)}
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary={Labels?.Compose} />
            </ListItemButton>
          </List>
        </Grid>
        {selectedIndex === 0 ? (
          <Grid item sm={8} lg={9} xl={10} sx={{ minHeight: "80vh" }}>
            {/* {Object.keys(messageDetail).length === 0 ? (
              <>
                <FormControl
                  sx={{ m: 1, background: "#6DA09C1F", width: "98%" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    placeholder={Labels?.search}
                    endAdornment={
                      <InputAdornment position="start">
                        <IconButton component="span">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {messageList.length === 0 ? (
                        <TableCell>
                          <Typography>{Labels?.InboxEmpty}</Typography>
                        </TableCell>
                      ) : (
                        messageList.map((data, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              sx={{
                                borderBottom:
                                  " 1px solid rgba(224, 224, 224, 1)",

                                cursor: "pointer",
                              }}
                              onClick={() => setMessageDetail(data)}
                            >
                              <TableCell
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderBottom: "none",
                                }}
                              >
                                <Avatar
                                  sx={{ mr: 1 }}
                                  src={`${PUBLIC_IMAGE_FOLDER}/${data.logo}`}
                                />
                                <Typography>{data?.name}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{data?.message}</Typography>
                              </TableCell>

                              <TableCell>
                                <Chip
                                  icon={<ReplyRoundedIcon />}
                                  label="Reply"
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer!important",
                                  }}
                                  onClick={(e) => replayMesssage(e, data.name)}
                                />
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div style={{ padding: "10px" }}>
                <Box sx={{ p: 1, border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <div style={{ textAlign: "right", cursor: "pointer" }}>
                    <CancelOutlinedIcon onClick={() => setMessageDetail({})} />
                  </div>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={`${PUBLIC_IMAGE_FOLDER}/${messageDetail.logo}`}
                    />
                    <Typography sx={{ p: 2 }}>{messageDetail?.name}</Typography>
                  </Box>
                  <Typography sx={{ p: 1, fontSize: "14px" }}>
                    {messageDetail?.message}
                  </Typography>
                  <Box sx={{ P: 1, textAlign: "-moz-right" }}>
                    <Chip
                      icon={<ReplyRoundedIcon />}
                      label="Reply"
                      variant="outlined"
                      onClick={(e) => replayMesssage(e, messageDetail?.name)}
                    />
                  </Box>
                </Box>
              </div>
            )} */}
            <Box sx={{ p: 2, mt: 1 }}>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : sentMessageList.length === 0 ? (
                <>
                  <Typography>{Labels?.Messagesempty}</Typography>
                </>
              ) : (
                sentMessageList.map((data, index) => (
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #696969",
                      margin: "5px 0px",
                    }}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        width="100%"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={2} md={3} sm={12} xs={12}>
                            <Item>
                              <Avatar
                                src={`${data.fromUserLogo}`}
                                sx={{ mr: 1, height: 40, width: 40 }}
                              />
                              <Typography
                                sx={{ fontSize: "13px", mr: 1, mb: 1 }}
                              >
                                {data?.fromUserName}
                              </Typography>

                              {Array.isArray(data.replies) &&
                                data.replies.length !== 0 && (
                                  <Typography sx={{ fontSize: "13px" }}>
                                    ({data.replies.length})
                                  </Typography>
                                )}
                            </Item>
                          </Grid>
                          <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Item>
                              <Typography sx={{ textAlign: "justify" }}>
                                {data?.message}
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <Item>
                              <Chip
                                icon={<ReplyRoundedIcon />}
                                label={Labels.reply}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer!important",
                                  mr: 3,
                                }}
                                onClick={(e) => replayMesssage(e, data)}
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionSummary>

                    {data.replies
                      ? Array.isArray(data.replies) &&
                        data.replies.map((data, index) => (
                          <AccordionDetails
                            sx={{
                              border: "1px solid #F0E5E5",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* {data.createdOn} */}
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.From}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.fromUserLogo?.endsWith(
                                      "/"
                                    ) ? null : (
                                      <Avatar
                                        src={`${data?.fromUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.fromUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.To}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.toUserLogo?.endsWith("/") ? null : (
                                      <Avatar
                                        src={`${data?.toUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.toUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0.5}>
                              <Grid item xs={1}>
                                <Item>{Labels.Message}</Item>
                              </Grid>
                              <Grid item xs={0}>
                                <Item> :</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item> {data?.message}</Item>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        ))
                      : ""}
                  </Accordion>
                ))
              )}
            </Box>
          </Grid>
        ) : selectedIndex === 1 ? (
          <Grid item sm={8} lg={9} xl={10} sx={{ minHeight: "80vh" }}>
            {/* {Object.keys(messageDetail).length === 0 ? (
              <>
                <FormControl
                  sx={{ m: 1, background: "#6DA09C1F", width: "98%" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    placeholder={Labels?.search}
                    endAdornment={
                      <InputAdornment position="start">
                        <IconButton component="span">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {messageList.length === 0 ? (
                        <TableCell>
                          <Typography>{Labels?.InboxEmpty}</Typography>
                        </TableCell>
                      ) : (
                        messageList.map((data, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              sx={{
                                borderBottom:
                                  " 1px solid rgba(224, 224, 224, 1)",

                                cursor: "pointer",
                              }}
                              onClick={() => setMessageDetail(data)}
                            >
                              <TableCell
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderBottom: "none",
                                }}
                              >
                                <Avatar
                                  sx={{ mr: 1 }}
                                  src={`${PUBLIC_IMAGE_FOLDER}/${data.logo}`}
                                />
                                <Typography>{data?.name}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{data?.message}</Typography>
                              </TableCell>

                              <TableCell>
                                <Chip
                                  icon={<ReplyRoundedIcon />}
                                  label="Reply"
                                  variant="outlined"
                                  sx={{
                                    cursor: "pointer!important",
                                  }}
                                  onClick={(e) => replayMesssage(e, data.name)}
                                />
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div style={{ padding: "10px" }}>
                <Box sx={{ p: 1, border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <div style={{ textAlign: "right", cursor: "pointer" }}>
                    <CancelOutlinedIcon onClick={() => setMessageDetail({})} />
                  </div>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={`${PUBLIC_IMAGE_FOLDER}/${messageDetail.logo}`}
                    />
                    <Typography sx={{ p: 2 }}>{messageDetail?.name}</Typography>
                  </Box>
                  <Typography sx={{ p: 1, fontSize: "14px" }}>
                    {messageDetail?.message}
                  </Typography>
                  <Box sx={{ P: 1, textAlign: "-moz-right" }}>
                    <Chip
                      icon={<ReplyRoundedIcon />}
                      label="Reply"
                      variant="outlined"
                      onClick={(e) => replayMesssage(e, messageDetail?.name)}
                    />
                  </Box>
                </Box>
              </div>
            )} */}
            <Box sx={{ p: 2, mt: 1 }}>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={100}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : messageList.length === 0 ? (
                <>
                  <Typography>{Labels?.Messagesempty}</Typography>
                </>
              ) : (
                messageList.map((data, index) => (
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #696969",
                      margin: "5px 0px",
                    }}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        width="100%"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={2} md={3} sm={12} xs={12}>
                            <Item>
                              <Avatar
                                src={`${data.fromUserLogo}`}
                                sx={{ mr: 1, height: 40, width: 40 }}
                              />
                              <Typography
                                sx={{ fontSize: "13px", mr: 1, mb: 1 }}
                              >
                                {data?.fromUserName}
                              </Typography>

                              {Array.isArray(data.replies) &&
                                data.replies.length !== 0 && (
                                  <Typography sx={{ fontSize: "13px" }}>
                                    ({data.replies.length})
                                  </Typography>
                                )}
                            </Item>
                          </Grid>
                          <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Item>
                              <Typography sx={{ textAlign: "justify" }}>
                                {data?.message}
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <Item>
                              <Chip
                                icon={<ReplyRoundedIcon />}
                                label={Labels.reply}
                                variant="outlined"
                                sx={{
                                  cursor: "pointer!important",
                                  mr: 3,
                                }}
                                onClick={(e) => replayMesssage(e, data)}
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionSummary>

                    {data.replies
                      ? Array.isArray(data.replies) &&
                        data.replies.map((data, index) => (
                          <AccordionDetails
                            sx={{
                              border: "1px solid #F0E5E5",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* {data.createdOn} */}
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.From}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.fromUserLogo?.endsWith(
                                      "/"
                                    ) ? null : (
                                      <Avatar
                                        src={`${data?.fromUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.fromUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={1}>
                                <Item>{Labels.To}</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    : &nbsp;
                                    {data?.toUserLogo?.endsWith("/") ? null : (
                                      <Avatar
                                        src={`${data?.toUserLogo}`}
                                        sx={{
                                          maxWidth: 50,
                                          maxHeight: 50,
                                          borderRadius: "100%%",
                                        }}
                                      />
                                    )}
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        {data?.toUserName}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0.5}>
                              <Grid item xs={1}>
                                <Item>{Labels.Message}</Item>
                              </Grid>
                              <Grid item xs={0}>
                                <Item> :</Item>
                              </Grid>
                              <Grid item xs={10}>
                                <Item> {data?.message}</Item>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        ))
                      : ""}
                  </Accordion>
                ))
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}

export default EmployerMessages;
