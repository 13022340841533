import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import "./table.css";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WorkIcon from "@mui/icons-material/Work";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PublicIcon from "@mui/icons-material/Public";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";
import MessageToJobSeekerModal from "../EmployerComponents/MessageToJobSeekerModal";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";

export default function AppliedJobSeekers({
  setJobSeekerListPage,
  setJobDetailsCall,
}) {
  const { id } = useParams();
  const classes = useStyles();
  const [backdropOpen, setBackdrop] = useState(false);
  const [Skeletonloading, setloading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(0);
  const [appliedJobSeekers, setAppliedJobSeekers] = useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const getAppliedJobSeekers = async () => {
    setloading(true);
    if (id) {
      const result = await axiosAPI.post(`/applied?jobid=${id}`);
      const { success, data, message } = result.data;
      if (success === true) {
        setAppliedJobSeekers(data);
        setloading(false);
      } else {
        errorNotify(message);
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };
  useEffect(() => {
    getAppliedJobSeekers();
    setJobSeekerListPage(2);
    window.scrollTo(0, 0);
  }, [isApiCall]);

  const shortListJobSeeker = async (event, jsid) => {
    event.stopPropagation();
    const postData = {
      jobid: id,
      jobseekerid: jsid,
      type: "shortlist",
      language:localStorage.getItem("language")
    };
    try {
      const result = await axiosAPI.post("/shortlist", postData);
      const { success, data, message } = result.data;
      if (success) {
        succesNotify(message);
        setIsApiCall(Math.random());
        setJobDetailsCall(Math.random());
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // --------------Get JobSeeker Profile------------------
  const [jobSeekerData, setJobSeekerData] = useState({});
  const getJobSeekerData = async (jobSeekerId) => {
    setJobSeekerData({ name: "" });
    setBackdrop(true);

    try {
      const postData = {
        jobseekerid: jobSeekerId,
      };
      const result = await axiosAPI.post("/profile", postData);
      const { success, data } = result.data;
      if (success) {
        setJobSeekerData(data);
        setBackdrop(false);
      } else {
        setBackdrop(false);
      }
    } catch (error) {
      setBackdrop(false);
      //// console.log(error.message);
    }
  };
  // ----------------Download Resume---------------------
  const downloadResume = async () => {
    const resumeUrl = PUBLIC_IMAGE_FOLDER + "/" + jobSeekerData?.resumePath;
    if (jobSeekerData?.resumePath) {
      try {
        const response = await fetch(resumeUrl);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "document.pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    } else {
      infoNotify(Labels.noFileUploaded);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      {/* -------------------- Dialog ------------------- */}
      <MessageToJobSeekerModal
        open={messageModal}
        handleClose={setMessageModal}
        jobSeekerId={jobSeekerData?.userid}
        jobSeekerName={jobSeekerData?.displayName}
      />
      <SimpleBackdrop open={backdropOpen} />
      {!Skeletonloading && (
        <>
          {Object.keys(jobSeekerData).length === 0 ? (
            <>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="center"
                sx={{ my: 1 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#1C614E", fontWeight: 500, mb: 4 }}
                >
                  {Labels?.Showing} {appliedJobSeekers.length}{" "}
                  {appliedJobSeekers.length == 1
                    ? Labels?.Applicant
                    : Labels?.Applicants}
                </Typography>
              </Stack>

              <Grid container spacing={2}>
                {appliedJobSeekers.length > 0 ? (
                  appliedJobSeekers.map((jobseeker) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      xl={3}
                      key={jobseeker.jsjm_id}
                    >
                      <Card
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          borderRadius: 2,
                          transition: "background-color 0.4s",
                          cursor: "pointer",
                          boxShadow: "none",
                          p: 2,
                        }}
                        onClick={() => getJobSeekerData(jobseeker.jobseekerid)}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {jobseeker?.pic?.endsWith("/") ? null : (
                            <Avatar
                              src={`${jobseeker?.pic}`}
                              sx={{
                                maxWidth: 50,
                                maxHeight: 50,
                                borderRadius: "100%",
                              }}
                            />
                          )}
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {jobseeker?.name}
                            </Typography>
                            <Typography variant="body2">
                              {jobseeker?.city}
                            </Typography>
                          </Box>
                        </Stack>
                        {/* <Stack
                      direction="row"
                      spacing={1}
                      sx={{ my: 1, height: "25px" }}
                    >
                      {jobseeker.skills
                        ? Array.isArray(jobseeker.skills) &&
                          jobseeker.skills
                            .map((data) => (
                              <Chip
                                key={data.id}
                                label={data?.skillName}
                                size="small"
                                sx={{
                                  borderRadius: 1,
                                  backgroundColor: "rgba(186, 140, 4, 0.06)",
                                  color: "#BA8C04",
                                  border: "1px solid rgba(0, 0, 0, 0.1)",
                                }}
                              />
                            ))
                            .slice(0, 3)
                        : ""}
                    </Stack> */}
                        <Typography variant="caption">
                          {Labels?.Appliedon}&nbsp;
                          {new Date(jobseeker?.appliedOn).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </Typography>
                        <Stack
                          sx={{ my: 1 }}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "capitalize",
                              color: "#2B4947",
                              border: "1px solid #e0e0e0",
                            }}
                            startIcon={<VerifiedUserRoundedIcon />}
                            onClick={(event) => {
                              shortListJobSeeker(event, jobseeker.jobseekerid);
                            }}
                          >
                            {Labels?.Shortlist}
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "capitalize",
                              color: "#2B4947",
                              border: "1px solid #e0e0e0",
                            }}
                          >
                            {Labels?.View}
                          </Button>
                        </Stack>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2">
                    {/* {Labels?.NoAppliedCandidates} */}
                  </Typography>
                )}
              </Grid>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={8}>
                <Paper elevation={0} sx={{ p: 2 }}>
                  <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                    <HighlightOffIcon
                      sx={{ color: "#2B4947", cursor: "pointer" }}
                      onClick={() => setJobSeekerData({})}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ my: 1 }}
                  >
                    {/* ------------ Shorlist ----------- */}
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#2B4947",
                        border: "1px solid #e0e0e0",
                      }}
                      startIcon={<VerifiedUserRoundedIcon />}
                      onClick={(event) => {
                        shortListJobSeeker(event, jobSeekerData?.userid);
                      }}
                    >
                      {Labels?.Shortlist}
                    </Button>
                    {/* ------------ Resume ----------- */}
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#2B4947",
                        border: "1px solid #e0e0e0",
                      }}
                      startIcon={<FileDownloadIcon />}
                      onClick={downloadResume}
                    >
                      {Labels?.Resume}
                    </Button>
                    {/* ------------ Message ----------- */}
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#2B4947",
                        border: "1px solid #e0e0e0",
                      }}
                      startIcon={<SendIcon />}
                      onClick={() => setMessageModal(true)}
                    >
                      {Labels?.Send}
                    </Button>
                  </Stack>

                  {/* -------------------- profile -------------------- */}
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 2 }}
                  >
                    {jobSeekerData?.profilePicPath?.endsWith("/") ? null : (
                      <Avatar
                        src={`${jobSeekerData?.profilePicPath}`}
                        sx={{ my: 1, width: 60, height: 60 }}
                      />
                    )}

                    <Typography
                      variant="h6"
                      sx={{ color: "#2B4947", fontWeight: 500 }}
                    >
                      {jobSeekerData?.displayName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#84827D", fontWeight: 500 }}
                    >
                      {jobSeekerData?.designationName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#84827D" }}>
                      {jobSeekerData?.currentEmployer}
                    </Typography>
                  </Stack>
                  <Grid container spacing={2}>
                    {/* ---------------- Career Information ---------------- */}
                    <Grid item xs={12} lg={6}>
                      <Box
                        sx={{
                          border: "1px solid #c3e1d9",
                          p: 2,
                          borderRadius: 2,
                          minHeight: "230px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <WorkIcon
                            sx={{
                              mr: 1,
                              color: "rgba(0, 0, 0, 0.6)",
                              fontSize: "2rem",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {Labels?.careerProfile}
                          </Typography>
                        </Stack>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.designation}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.designationName}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.ExperienceLevel}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.experienceLevelName}
                                </Typography>
                              </td>
                            </tr>
                            {/* <tr>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {Labels?.WorkStatus}
                        </Typography>
                      </td>
                      <td style={{ width: 25 }}>
                        <Typography variant="body2">:</Typography>
                      </td>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {jobSeekerData?.workStatus}
                        </Typography>
                      </td>
                    </tr> */}
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.TotalExperience}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                {jobSeekerData?.totalExperience ? (
                                  <Typography variant="body2">
                                    {jobSeekerData?.totalExperience}{" "}
                                    {Labels?.Years}
                                  </Typography>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.CurrentCompany}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.currentEmployer}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Grid>
                    {/* ---------------- Personal Information ---------------- */}
                    <Grid item xs={12} lg={6}>
                      <Box
                        sx={{
                          border: "1px solid #c3e1d9",
                          p: 2,
                          borderRadius: 2,
                          minHeight: "230px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <AccountCircleIcon
                            sx={{ mr: 1, color: "#00000099", fontSize: 30 }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {Labels?.personalInformation}
                          </Typography>
                        </Stack>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.name}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.displayName}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.dateofBirth}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.dateOfBirth}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.Gender}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.genderName}
                                </Typography>
                              </td>
                            </tr>
                            {/* <tr>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {Labels?.preferredLanguage}
                        </Typography>
                      </td>
                      <td style={{ width: 25 }}>
                        <Typography variant="body2">:</Typography>
                      </td>
                      <td style={{ width: 175 }}>
                        <Typography variant="body2">
                          {jobSeekerData?.languageName}
                        </Typography>
                      </td>
                    </tr> */}
                            <tr>
                              <td style={{ width: 150 }}>
                                <Typography variant="body2">
                                  {Labels?.location}
                                </Typography>
                              </td>
                              <td style={{ width: 25 }}>
                                <Typography variant="body2">:</Typography>
                              </td>
                              <td style={{ width: 175 }}>
                                <Typography variant="body2">
                                  {jobSeekerData?.city}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Grid>

                    {/* _________________Skills__________________________________ */}
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          border: "1px solid #c3e1d9",
                          p: 2,
                          borderRadius: 2,
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {/* <WorkIcon
                    sx={{
                      mr: 1,
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "2rem",
                    }}
                  /> */}
                          <TipsAndUpdatesIcon
                            sx={{
                              mr: 1,
                              color: "rgba(0, 0, 0, 0.6)",
                              fontSize: "2rem",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {Labels.skills}
                          </Typography>
                        </Stack>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  {jobSeekerData?.skills?.map((a) => {
                                    return (
                                      <Chip
                                        label={a.name}
                                        variant="outlined"
                                        sx={{ margin: "5px" }}
                                      />
                                    );
                                  })}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* -------------------- Employment -------------------- */}
                    <Grid item xs={12} md={12} lg={12} xl={6}>
                      <Box
                        sx={{
                          my: 1,
                          border: "1px solid #c3e1d9",
                          borderRadius: 1,
                          p: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {Labels?.EmploymentHistory}
                        </Typography>
                        {jobSeekerData.employment &&
                        Array.isArray(jobSeekerData.employment) &&
                        jobSeekerData.employment.length === 0 ? (
                          <Typography variant="body1">
                            {Labels?.NoEmploymentHistory}
                          </Typography>
                        ) : (
                          jobSeekerData.employment &&
                          Array.isArray(jobSeekerData.employment) &&
                          jobSeekerData.employment.map((data) => (
                            <List>
                              <ListItem
                                key={data?.jseh_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  p: 0,
                                }}
                              >
                                <ListItemIcon>
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: 15, color: "#1C614E" }}
                                  />
                                </ListItemIcon>

                                <ListItemText
                                  primary={data.designationName}
                                  secondary={
                                    <Stack
                                      direction={{ xs: "column", sm: "column" }}
                                    >
                                      <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">
                                          {data.company}
                                        </Typography>
                                      </Stack>
                                      <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">
                                          {data.jobtypename}
                                        </Typography>
                                      </Stack>
                                      <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">
                                          {data.startMonth}
                                        </Typography>
                                        <Typography variant="body2">
                                          {data.startyear}
                                        </Typography>
                                        <Typography variant="body2">
                                          {Labels?.To}
                                        </Typography>
                                        <Typography variant="body2">
                                          {data.endMonth}
                                        </Typography>
                                        <Typography variant="body2">
                                          {data.endyear}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  }
                                />
                              </ListItem>
                            </List>
                          ))
                        )}
                      </Box>
                    </Grid>
                    {/* -------------------- Education -------------------- */}
                    <Grid item xs={12} md={12} lg={12} xl={6}>
                      <Box
                        sx={{
                          my: 1,
                          border: "1px solid #c3e1d9",
                          borderRadius: 1,
                          p: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {Labels?.EducationHistory}
                        </Typography>
                        {jobSeekerData.employment &&
                        Array.isArray(jobSeekerData.education) &&
                        jobSeekerData.education.length === 0 ? (
                          <Typography variant="body2">
                            {Labels?.NoEducationHistory}
                          </Typography>
                        ) : (
                          jobSeekerData.education &&
                          Array.isArray(jobSeekerData.education) &&
                          jobSeekerData.education.map((data) => (
                            <List>
                              <ListItem
                                key={data?.jseh_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  p: 0,
                                }}
                              >
                                <ListItemIcon>
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: 15, color: "#1C614E" }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={data.CourseName}
                                  secondary={
                                    <Stack
                                      direction={{ xs: "column", sm: "column" }}
                                    >
                                      <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">
                                          {data.institution}
                                        </Typography>
                                      </Stack>

                                      <Stack direction="row" spacing={1}>
                                        <Typography
                                          variant="body2"
                                          sx={{ mx: 2 }}
                                        >
                                          {data.jobtypename}
                                        </Typography>
                                      </Stack>

                                      <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">
                                          {data.startYear}
                                        </Typography>
                                        <Typography variant="body2">
                                          {Labels?.To}
                                        </Typography>
                                        <Typography variant="body2">
                                          {data.endYear}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  }
                                />
                              </ListItem>
                            </List>
                          ))
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* -------------------- Applied JobSeekers -------------------- */}
              <Grid item xs={12} md={6} lg={4}>
                <Card
                  sx={{
                    borderRadius: 3,
                    p: 0,
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#1C614E", p: 2 }}
                  >
                    {Labels?.AppliedCandidates}
                  </Typography>
                  <Divider />
                  <Box
                    sx={{
                      height: { xs: "100vh", md: "70vh" },
                      overflow: "auto",
                    }}
                    className={classes.customColoredScrollbar}
                  >
                    {appliedJobSeekers.length > 0 ? (
                      appliedJobSeekers.map((val, i) => (
                        <Card
                          key={i}
                          sx={{
                            borderRadius: 1,
                            boxShadow: "none",
                            borderBottom: "1px solid #1C614E33",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#ffffffe5",
                            },
                          
                          }}
                          onClick={() => getJobSeekerData(val?.jobseekerid)}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            p={1}
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <Avatar
                                src={
                                  val.grpLogoPath === null ||
                                  "" ||
                                  val?.grpLogoPath?.endsWith("/")
                                    ? `${val.groupName.charAt(0)}`
                                    : `${val.pic}`
                                }
                                alt=" "
                                style={{
                                  maxWidth: 30,
                                  maxHeight: 30,
                                }}
                              />

                              <Stack>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: "#2B4947", fontWeight: 600 }}
                                >
                                  {val.name}
                                </Typography>
                                <Stack direction="row" alignItems="center">
                                  <PublicIcon sx={{ fontSize: 17 }} />
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#1C614E" }}
                                  >
                                    &nbsp;{val?.status_name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                            <Stack>
                              <InfoIcon />
                            </Stack>
                          </Stack>
                        </Card>
                      ))
                    ) : (
                      <Typography variant="body1" sx={{ p: 1 }}>
                        {Labels?.NoAppliedCandidates}
                      </Typography>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {Skeletonloading && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Item>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginBottom: "10px" }}
                />
                <Skeleton variant="rounded" width={`100%`} height={120} />
              </Item>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Item>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginBottom: "10px" }}
                />
                <Skeleton variant="rounded" width={`100%`} height={120} />
              </Item>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Item>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginBottom: "10px" }}
                />
                <Skeleton variant="rounded" width={`100%`} height={120} />
              </Item>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Item>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginBottom: "10px" }}
                />
                <Skeleton variant="rounded" width={`100%`} height={120} />
              </Item>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
