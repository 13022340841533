import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import JobOffers from "./JobOffers";
import Advertisement from "./Advertisement";
import JobCompanies from "./JobCompanies";
import Workfromhome from "./Workfromhome";
import Advertisement2 from "./Advertisement2";
import Companylogo from "./Companylogo";
import { axiosAPI } from "../Axios/Axios";
import { Chip, Paper } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { warningNotify } from "../CommonCode/Commonfunc";
import video from "../../Images/Msquared-Training.mp4";
import img1 from "../../Images/las1.jpg";
import img2 from "../../Images/las2.jpg";
import "./Style.css";
import { getAllList } from "../../redux/getListSlice";
import { getProfanityWord } from "../../redux/Profanity";
import MostSearchedJobs from "./MostSearchedJobs";
import DesiredIndustry from "./DesiredIndustry";

const Landingpage_1 = () => {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const defaultTheme = createTheme();
  const allList = useSelector((state) => state.allList.list);
  const Labels = useSelector((state) => state.allLabels.labels);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("sessionTimeoutNotified")) {
      warningNotify(Labels.sessionTimeout);
      localStorage.removeItem("sessionTimeoutNotified");
    }
  }, []);

  const getList = () => {
    dispatch(getAllList());
  };
  useEffect(() => {
    getList();
  }, [Labels]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container
        maxWidth="100%"
        sx={{ margin: "0 auto", padding: "0 !important" }}
      >
        {/* -----------------------Banner------------------------ */}
        <MainFeaturedPost />
        <Container maxWidth="xl">
          <Paper elevation={0}>
            {/* -----------------------Most searched Jobs------------------------ */}
            <MostSearchedJobs />
            {/* -----------------------Categories------------------------ */}
            {/* <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              {allList?.categories &&
                allList?.categories
                  .map((post) => <FeaturedPost key={post.id} post={post} />)
                  .slice(0, 8)}
              <Chip
                label={Labels?.MoreCategories}
                icon={<ArrowForwardIcon />}
                variant="outlined"
                sx={{ fontSize: 15, my: 5 }}
                onClick={() => redirect("/allCategories")}
              />
            </Grid> */}

            {/* ---------------------------Desired Industry---------------------- */}
            <DesiredIndustry />
            {/* ----------------------- Advertisement---------------------- */}
            <Advertisement />
            {/* -----------------------Job Offers------------------------ */}
            <JobOffers />
            {/* -----------------------Video section------------------------ */}
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                mx: "auto",
                mb: { xs: 0, sm: 7 },
              }}
            >
              <Grid
                xs={12}
                sm={6}
                md={6}
                lg={3}
                sx={{
                  textAlign: "center",
                  order: { xs: 1, lg: 1 },
                  marginBottom: 3,
                }}
              >
                <img src={img1} style={{ width: "300px", height: "365px" }} />
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={6}
                sx={{
                  textAlign: "center",
                  order: { xs: 3, lg: 2 },
                  marginBottom: 3,
                }}
              >
                <video autoPlay loop muted controls>
                  <source src={video} type="video/mp4" />
                </video>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={6}
                lg={3}
                sx={{
                  textAlign: "center",
                  order: { xs: 2, lg: 3 },
                  marginBottom: 3,
                }}
              >
                <img src={img2} style={{ width: "300px", height: "365px" }} />
              </Grid>
            </Grid>
            {/* -----------------------Best Companies------------------------ */}
            <JobCompanies />
            {/* <Workfromhome /> */}
            <Advertisement2 />
            {/* <Companylogo /> */}
          </Paper>
        </Container>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Landingpage_1;
