import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Popover,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";

export default function JobSeekersList({
  jobSeekerListPage,
  isJobDetailsCalled,
}) {
  const redirect = useNavigate();
  const jobId = localStorage.getItem("jobId");
  // --------------Get jobDetails------------------
  const [jobDetails, setJobDetails] = useState({});
  const getjobdetails = async () => {
    const result = await axiosAPI.post(`/getJobInfo?jobid=${jobId}`);
    const { success, data, message } = result.data;
    if (success === true) {
      setJobDetails(data);
    } else {
      //// console.log(message);
    }
  };
  useEffect(() => {
    getjobdetails();
  }, [isJobDetailsCalled]);

  const [selectedOption, setSelectedOption] = useState("jobInfo");
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleSelectChange = (event) => {
    setSelectedOption(profanityfn(ProfanityList,event.target.value));
  };

  const redirectToMatchingJobSeekers = (event) => {
    event.stopPropagation();
    redirect(`/matchingJobSeekers/${jobId}`);
  };
  const redirectToMarkedJobSeekers = (event) => {
    event.stopPropagation();
    redirect(`/markedJobSeekers/${jobId}`);
  };
  const redirectToAppliedJobSeekers = (event) => {
    event.stopPropagation();
    redirect(`/appliedJobSeekers/${jobId}`);
  };
  const redirectToShortListedJobSeekers = (event) => {
    event.stopPropagation();
    redirect(`/shortListedJobSeekers/${jobId}`);
  };
  const redirectToEnquiryMessages = (event) => {
    event.stopPropagation();
    redirect(`/jobEnquires/${jobId}`);
  };
  const redirectToJobInfo = (event) => {
    event.stopPropagation();
    redirect(`/jobInfo/${jobId}`);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          alignItems="center"
          spacing={1}
          direction="row"
          sx={{
            my: 2,
            cursor: "pointer",
          }}
          onClick={() => redirect(`/jobInfo/${jobId}`)}
        >
         
        </Stack>
        <FormControl sx={{ display: { xs: "block", xl: "none" }, my: 1 }}>
          {/* <InputLabel>Options</InputLabel> */}
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            sx={{ minWidth: 200, background: "#fff", border: "none" }}
          >
            <MenuItem
              value="jobInfo"
              onClick={() => redirect(`/jobInfo/${jobId}`)}
            >
              {Labels?.Details}
            </MenuItem>
            <MenuItem
              value="appliedJobSeekers"
              onClick={() => redirect(`/appliedJobSeekers/${jobId}`)}
            >
              {Labels?.Applied}({jobDetails?.applied})
            </MenuItem>
            <MenuItem
              value="shortListedJobSeekers"
              onClick={() => redirect(`/shortListedJobSeekers/${jobId}`)}
            >
              {Labels?.Shortlisted}({jobDetails?.shortlist})
            </MenuItem>
            <MenuItem
              value="markedJobSeekers"
              onClick={() => redirect(`/markedJobSeekers/${jobId}`)}
            >
              {Labels?.Marked}({jobDetails?.marked})
            </MenuItem>
            <MenuItem
              value="matchingJobSeekers"
              onClick={() => redirect(`/matchingJobSeekers/${jobId}`)}
            >
              {Labels?.Recommended}({jobDetails?.matching})
            </MenuItem>
            <MenuItem
              value="enquiryMessages"
              onClick={() => redirect(`/jobEnquires/${jobId}`)}
            >
              {Labels?.Enquiries} ({jobDetails?.enquiry})
            </MenuItem>
          </Select>
        </FormControl>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            display: { xs: "none", xl: "flex" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 5 ? "#1C614E" : "white",
              color: jobSeekerListPage === 5 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 5 ? "#1C614E" : "white",
                color: jobSeekerListPage === 5 ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(event) => redirectToJobInfo(event)}
          >
            {Labels?.Details}
          </Button>

          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 2 ? "#1C614E" : "white",
              color: jobSeekerListPage === 2 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 2 ? "#1C614E" : "white",
                color: jobSeekerListPage === 2 ? "white" : "black",
              }, "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(event) => redirectToAppliedJobSeekers(event)}
          >
            {Labels?.Applied}({jobDetails?.applied?jobDetails?.applied:0})
          </Button>

          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 3 ? "#1C614E" : "white",
              color: jobSeekerListPage === 3 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 3 ? "#1C614E" : "white",
                color: jobSeekerListPage === 3 ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              }
            }}
            onClick={(event) => redirectToShortListedJobSeekers(event)}
          >
            {Labels?.Shortlisted}({jobDetails?.shortlist?jobDetails?.shortlist:0})
          </Button>

          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 1 ? "#1C614E" : "white",
              color: jobSeekerListPage === 1 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 1 ? "#1C614E" : "white",
                color: jobSeekerListPage === 1 ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(event) => redirectToMarkedJobSeekers(event)}
          >
            {Labels?.Marked}({jobDetails?.marked?jobDetails?.marked:0})
          </Button>

          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 0 ? "#1C614E" : "white",
              color: jobSeekerListPage === 0 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 0 ? "#1C614E" : "white",
                color: jobSeekerListPage === 0 ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(event) => redirectToMatchingJobSeekers(event)}
          >
            {Labels?.Recommended}({jobDetails?.matching?jobDetails?.matching:0})
          </Button>

          <Button
            variant="contained"
            sx={{
              background: jobSeekerListPage === 4 ? "#1C614E" : "white",
              color: jobSeekerListPage === 4 ? "white" : "black",
              boxShadow: "none",
              border: "1px solid #00000040",
              textTransform: "capitalize",
              my: 0.5,
              "&:hover": {
                backgroundColor: jobSeekerListPage === 4 ? "#1C614E" : "white",
                color: jobSeekerListPage === 4 ? "white" : "black",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            onClick={(event) => redirectToEnquiryMessages(event)}
          >
            {Labels?.Enquiries}({jobDetails?.enquiry})
          </Button>
        </Stack>
      </Stack>
      <Outlet />
    </>
  );
}
