import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { succesNotify } from "./Commonfunc";
import {profanityfn}  from "../Profanity/Profanity";

const JobInfo = ({
  value,
  setValue,
  style,
  placeholder,
  selectedNameCallback,
  setviewSelect,
  viewSelect,
}) => {
  const [Jobinfo, setJobinfo] = useState(null);
  useEffect(() => {
    const getJobInfo = async () => {
      const result = await axiosAPI.get("/getJobInformation");
      const { success, data } = await result.data;
      if (success === true) {
        setJobinfo(data);
      } else {
        setJobinfo([]);
      }
    };

    getJobInfo();
  }, []);

  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };

  useEffect(() => {
    setviewSelect(false);
  }, [value]);

  return (
    <FormControl sx={{ width: "100%" }}>
      <span style={{ fontSize: "14px" }}>{Labels.jobDescription}</span>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        value={value ? value : []}
        onChange={handleChange}
        fullWidth
        displayEmpty
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px",
          fontFamily: "Poppins",
          ...style,
        }}
      >
        {!viewSelect && (
          <MenuItem key={value.id} value={value}>
            {value.name}
          </MenuItem>
        )}
        {(!value || value=="") && <MenuItem value={""}>{Labels.jobDescription}</MenuItem>}

        {Jobinfo &&
          Jobinfo.map((val, index) => {
            if (value?.id) {
              if (val.id != value.id) {
                return (
                  <MenuItem key={val.id} value={val}>
                    {val.name}
                  </MenuItem>
                );
              }
            } else {
              return (
                <MenuItem key={val.id} value={val}>
                  {val.name}
                </MenuItem>
              );
            }
          })}
      </Select>
    </FormControl>
  );
};

export default JobInfo;
