// import React, { Fragment, useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { axiosAPI } from "../Axios/Axios";
// import { InputLabel, TextField } from "@mui/material";
// import { useSelector } from "react-redux";

// const Country = ({ style, label, value, setValue, setStateId }) => {
//   const [Countries, setCountries] = useState([]);
//   useEffect(() => {
//     const getCountries = async () => {
//       const result = await axiosAPI.get("/getCountries");
//       const { success, data } = await result.data;
//       if (success === true) {
//         setCountries(data);
//       } else {
//         setCountries([]);
//       }
//     };
//     getCountries();
//   }, []);
//   const handleChange = (event) => {
//     setValue(event.target.value);
//     setStateId("");
//   };
//   const Labels = useSelector((state) => state.allLabels.labels);
//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <Select
//         value={value}
//         onChange={handleChange}
//         placeholder={label}
//         displayEmpty
//         variant="outlined"
//         sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
//       >
//         <MenuItem value="" disabled>
//           <span style={{ color: "#bcbcbc" }}>{Labels?.SelectCountry}</span>
//         </MenuItem>
//         {Countries &&
//           Countries.map((val, index) => (
//             <MenuItem key={index} value={val.id}>
//               {val.name}
//             </MenuItem>
//           ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default Country;

import React, { Fragment, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, TextField } from "@mui/material";
import { updateLoadingState } from "../../redux/Loader";

const EditBranchCountry = ({ value, setValue, setStateId, style }) => {
  const dispatch = useDispatch();
  const [Countries, setCountries] = useState([]);
  const usertype = localStorage.getItem("userType");
 const [newvalue,setnewvalue] = useState({})

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      if (success === true) {
        setCountries(data);
      } else {
        setCountries([]);
      }
    };
    getCountries();

    if(value!=undefined){
      setnewvalue({id:value.countryID, abbrName:"", name:value.countryName})
      setValue({id:value.countryID, abbrName:"", name:value.countryName})
    }

  }, [value]);
  const Labels = useSelector((state) => state.allLabels.labels);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setnewvalue(newValue)
    setStateId(null);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={newvalue}
        onChange={handleChange}
        options={Countries}
        
        sx={{ ...style }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectCountry} />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default EditBranchCountry;
