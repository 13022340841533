import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePassword,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import {
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import Group839 from "../../../Images/Group839.png";
import {
  createJobseekerDetails,
  updateJobSeekerDetails,
} from "../../../redux/JobSeekerDetailSlice";
import { updateLoginDetails } from "../../../redux/JobSeekerLoginDetailSlice";
import InputWithLabel from "../../Components/InputWithLabel";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Languages from "../../CommonCode/Languages";
import {profanityfn}  from "../../Profanity/Profanity";
import { doSignInWithGoogle } from "../../../AuthConfig/Auth";
import CustomButton from "../../Components/CustomButton";

export default function CustomJobseekerSignUp({ user, loginType }) {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const currentDate = new Date();
  const maxDate = new Date(currentDate);
  maxDate.setFullYear(currentDate.getFullYear() - 50);
  const maxDateDayjs = dayjs(maxDate);
  const [userDetl, setuserDetl] = useState({
    emailid: "",
    password: "",
  });
  const defaultstate = {
    emailid: "",
    password: "",
  };
  const [dob, setdob] = useState(null);
  const [Language, setLanguages] = useState("");

  const Labels = useSelector((state) => state.allLabels.labels);
  const shouldDisableDate = (date) => {
    return date.isAfter(maxDateDayjs);
  };
  const [isSigningIn, setIsSigningIn] = useState(false);

  const onGoogleSignIn = (e) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithGoogle()
        .then((res) => {
          axiosAPI
            .post("/login", {
              username: res.providerData[0].email,
              password: res.providerData[0].uid,
              userRole: "3",
            })
            .then((response) => {
              const { success, data, message } = response.data;
              if (success) {
                dispatch(updateLoginDetails(data));
                if (data?.preferredLanguage) {
                  localStorage.setItem("language", data?.preferredLanguage);
                } else {
                  localStorage.setItem("language", "en");
                }
                localStorage.setItem("token", data.token);
                localStorage.setItem("uid", data.uld_id);
                localStorage.setItem("resettedByAdmin", data.resettedByAdmin);
                setuserDetl(defaultstate);
                if (data.resettedByAdmin === 1) {
                  redirect("/emppwdchange");
                } else {
                  redirect("/jobseekerDashboard");
                  succesNotify(Labels.loginSuccessfully);
                }
                setIsButtonDisabled(false);
              } else {
                setIsButtonDisabled(false);

                const postdata = {
                  name: res.providerData[0].displayName,
                  username: res.providerData[0].email,
                  password: res.providerData[0].uid,
                  dob: new Date(localStorage.getItem("DOBLOGIN")),
                  socialMediaLogin: 0,
                  language: localStorage.getItem("LANGLOGIN"),
                };
                axiosAPI
                  .post("/registerJobSeeker", postdata)
                  .then((response) => {
                    const { success, message } = response.data;
                    if (success) {
                      // redirect("/login", { state: 1 });
                      succesNotify(Labels.accountCreated);
                      onGoogleSignIn();
                      setIsButtonDisabled(false);
                    } else {
                      setIsButtonDisabled(false);
                      errorNotify(message);
                    }
                  })
                  .catch((res) => {
                    // warningNotify(Labels.credentialValidation);
                  });
              }
            })
            .catch((e) => {
              setIsButtonDisabled(false);
            });
        })
        .catch((err) => {
          setIsSigningIn(false);
        });
    }
  };

  const subfn = () => {
    localStorage.setItem("DOBLOGIN", dob);
    localStorage.setItem("LANGLOGIN", Language);
    if (!dob) {
      errorNotify(Labels.DOBselect);
      setIsButtonDisabled(false);
    } else if (Language === "") {
      errorNotify(Labels.selectPreferredLanguage);
      setIsButtonDisabled(false);
    } else {
      onGoogleSignIn();
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        top: "60px",
        color: "#fff",
        background: "transparent",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: `url(${Group839})`,
        padding: "50px",
        boxShadow: "0",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 600,
          fontSize: {
            xs: "30px",
            lg: "40px",
          },
        }}
        color="inherit"
        gutterBottom
      >
        Welcome to 50Plus! We're excited to have you on board.
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 300,
          fontSize: {
            xs: "15px",
            lg: "20px",
          },
        }}
        color="inherit"
      >
        Before you sign in using {loginType}, please fill the fields shown
        below.
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label style={{ color: "white" }}>{Labels?.dateofBirth}</label>
            <span style={{ color: "red" }}>*</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ paddingTop: 0, background: "white" }}
              >
                <DatePicker
                  fullWidth
                  value={dob}
                  onChange={(date) => setdob(date.$d)}
                  shouldDisableDate={shouldDisableDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ margin: "10px 5px" }}>
            <label style={{ color: "white" }}>{Labels?.language}</label>
            <span style={{ color: "red" }}>*</span>
            <br />
            <Languages
              style={{
                width: "100%",
                background: "white",
              }}
              selectLabel={Labels.PreferredLanguages}
              value={Language}
              setValue={setLanguages}
            />
          </div>
        </Grid>
      </Grid>
      <Stack textAlign="center">
        <CustomButton
          label={Labels?.signUp}
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            width: "30%",
            margin: "20px auto",
            border: "2px solid white",
          }}
          onClick={subfn}
          disabled={isButtonDisabled}
        />
      </Stack>
    </Paper>
  );
}
