import { Box, Dialog, Stack, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/joy";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Qualification from "../../CommonCode/Qualification";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const EducationModal = ({ open, handleClose }) => {
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [educationhistory, setEducationhistory] = useState("");
  const [educationStartYear, setEducationStartYear] = React.useState("");
  const [educationEndYear, setEducationEndYear] = React.useState("");
  const [institution, setInstitution] = useState("");
  const today = new Date().toISOString().split("T")[0];

  const submitEducationHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);
    if (
      !educationhistory ||
      !institution ||
      !educationStartYear ||
      !educationEndYear
    ) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (institution.length > 200) {
      setButtonDisable(false);
      return errorNotify(Labels.institutionNameValidation);
    } else {
      setButtonDisable(false);

      const startDate = new Date(educationStartYear);
      const endDate = new Date(educationEndYear);

      const startYear = startDate.getFullYear().toString();
      const endYear = endDate.getFullYear().toString();

     
      const startMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(startDate);

      const endMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(endDate);



      if (startYear > endYear) {
        errorNotify(Labels.startyearshouldnot);
      } 
      
      else if (startYear === endYear) {
        const startMonthtest = startDate.getMonth();
        const endMonthtest = endDate.getMonth();

        if (startMonthtest > endMonthtest) {
          errorNotify(Labels.startmonthshouldnot);
        } 
        else {
          const postData = {
            educationID: educationhistory,
            institution: institution,
            startYear: startYear,
            endYear: endYear,
            startMonth: startMonth,
            endMonth: endMonth,
            language:localStorage.getItem("language")
          };

          const result = await axiosAPI.post("/addEducation", postData);
          const { success, message } = result.data;
          if (success === true) {
            succesNotify(message);
            handleClose();
            setButtonDisable(false);
          } else {
            errorNotify(message);
            setButtonDisable(false);
          }
        }
      } else {
        const postData = {
          educationID: educationhistory,
          institution: institution,
          startYear: startYear,
          endYear: endYear,
          startMonth: startMonth,
          endMonth: endMonth,
          language:localStorage.getItem("language")
        };

        const result = await axiosAPI.post("/addEducation", postData);
        const { success, message } = result.data;
        if (success === true) {
          succesNotify(message);
          handleClose();
          setButtonDisable(false);
        } else {
          errorNotify(message);
          setButtonDisable(false);
        }
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.Education}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#1C614E",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 2 }}>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Qualifications}
          </Typography>

          <Qualification
            value={educationhistory}
            setValue={setEducationhistory}
            style={{
              width: "100%",
            }}
          />
        </div>

        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Institution}
          </Typography>
          <TextfieldCustom
            style={{
              width: "100%",
            }}
            label={Labels?.Institution}
            name="institution"
            value={institution}
            onChange={(e) => setInstitution(profanityfn(ProfanityList,e.target.value))}
          />
        </div>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={educationStartYear}
              onChange={(event) =>
                setEducationStartYear(profanityfn(ProfanityList,event.target.value))
              }
              inputProps={{
                max: today,
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={educationEndYear}
              onChange={(event) =>
                setEducationEndYear(profanityfn(ProfanityList,event.target.value))
              }
              inputProps={{
                min: educationStartYear,
                max: today,
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#1C614E",
              },
            }}
            disabled={isButtonDisable}
            onClick={submitEducationHistory}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default EducationModal;
