import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  Avatar,
  Skeleton,
  Chip,
} from "@mui/material";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import CustomButton from "../../Components/CustomButton";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, useStyles } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import PublicIcon from "@mui/icons-material/Public";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import dummyLogo from "../../../Images/dummyLogo.png";
import EmployerPasswordChangeModal from "../EmployerProfile/EmployerPasswordChangeModal";
import MFAPopup from "../../CommonCode/MFA/MFAPopup";
import { useScreenWidth } from "../../CommonCode/ScreenWidth/useScreenWidth";

export default function EmployerDashboard({ routeChange }) {
  const redirect = useNavigate();
  const classes = useStyles();
  const [openPasswordReset, setopenPasswordReset] = useState(false);

  const [postedJobList, setPostedJobList] = useState([]);

  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );

  const [companyProfilee, setcompanyProfilee] = useState({});
  //// console.log(companyProfilee);
  // -------------------- Employer Data --------------------
  useEffect(() => {
    axiosAPI
      .post("/getCompanyProfile")
      .then((res) => {
        setcompanyProfilee(res.data.data);
      })
      .catch((err) => {
        //// console.log(err);
      });
  }, []);

  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const createJob = () => {
    redirect("/Createjob");
  };
  // -----------Job Listing Api -------------
  const [loading, setloading] = useState(true);

  const getPostedJobList = async () => {
    const result = await axiosAPI.post("/listjobs");
    const { success, data, message } = result.data;
    if (success) {
      setPostedJobList(data);
      setloading(false);
    } else {
      errorNotify(message);
      setloading(false);
    }
  };

  //---------------- Group ----------------
  const [loading2, setloading2] = useState(true);
  const [groups, setGroups] = useState([]);
  const getGroups = async () => {
    try {
      const result = await axiosAPI.post("/getMyGroupsList");
      const { data, status } = result.data;
      if (status) {
        setGroups(data);
        setloading2(false);
      } else {
        setloading2(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //---------------- Forum ----------------
  const [loading3, setloading3] = useState(true);
  const [myForum, setMyForum] = useState([]);
  const getMyForum = async () => {
    try {
      const result = await axiosAPI.post("/getMyForumList");
      const { data, status } = result.data;
      if (status) {
        setMyForum(data);
        setloading3(false);
      } else {
        setloading3(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  //  -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };
  useEffect(() => {
    if (resettedByAdmin === "1") {
      setopenPasswordReset(true);
    }
    routeChange(Math.random());
    getPostedJobList();
    getGroups();
    getMyForum();
    window.scroll(0, 0);
  }, []);

  const goToJobDetailsPage = (jobId) => {
    localStorage.setItem("jobId", jobId);
    redirect(`/jobInfo/${jobId}`);
  };

  const viewDetailedGroup = (id) => {
    redirect(`/group-detail/${id}`);
  };
  const viewDetailedForum = (id) => {
    redirect(`/forum-detail/${id}`);
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const screenWidth = useScreenWidth();
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: screenWidth > 1535 ? "fixed" : "relative",
          width: screenWidth > 1535 ? "82vw" : "auto",
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px 0",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  borderRadius: 1,
                  // margin: "10px 0",
                  p: 2,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="icon-box" style={{ background: "#F5FFF5" }}>
                    <HandshakeOutlinedIcon sx={{ color: "#136e16" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#136e16" }}>
                      0
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#656464" }}>
                      {Labels?.TotalHire}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  borderRadius: 1,
                  // margin: "10px 0",
                  p: 2,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="icon-box" style={{ background: "#FFF6F7" }}>
                    <WorkOutlineOutlinedIcon sx={{ color: "#ED4C5C" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#ED4C5C" }}>
                      {postedJobList?.length}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#656464" }}>
                      {postedJobList?.length==1?Labels?.PostedJob:Labels?.PostedJobs}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  borderRadius: 1,
                  // margin: "10px 0",
                  p: 2,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="icon-box" style={{ background: "#EFF7FF" }}>
                    <AddchartOutlinedIcon sx={{ color: "#1976D2" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#1976D2" }}>
                      {companyProfilee?.shortlistCount
                        ? companyProfilee?.shortlistCount
                        : "0"}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#656464" }}>
                      {Labels?.Shortlisted}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  borderRadius: 1,
                  // margin: "10px 0",
                  p: 2,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="icon-box" style={{ background: "#FFFCF3" }}>
                    <MailOutlineOutlinedIcon sx={{ color: "#BA8C04" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#BA8C04" }}>
                      {companyProfilee?.ChatmessageCount
                        ? companyProfilee?.ChatmessageCount
                        : "0"}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#656464" }}>
                      {companyProfilee?.ChatmessageCount == 1
                        ? Labels?.Message
                        : Labels?.Messages}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <Stack direction="row" justifyContent="space-between" padding={1.5}>
              <Typography variant="h6" sx={{ color: "#1C614E" }}>
                {Labels?.LatestJobs}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1C614E",
                  color:"white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                    color:"white"
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/employerJobList")}
              >
                {Labels?.ViewAll}
              </Button>
            </Stack>

            <Divider />
            <Stack
              style={{
                height: "70vh",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "5px 0",
              }}
              className={classes.customScrollbar}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={300}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={300}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={300}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={300}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={300}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : postedJobList.length === 0 ? (
                <Typography variant="body1" sx={{ p: 1 }}>
                  {Labels?.NoJobsPosted}
                </Typography>
              ) : (
                postedJobList.slice(0, 6).map((data) => (
                  <Box
                    key={data.eja_id}
                    sx={{
                      border: "1px solid rgba(28, 97, 78, 0.25)",
                      borderRadius: "4px",
                      padding: 1,
                      margin: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => goToJobDetailsPage(data.eja_id)}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar
                        src={`${data?.companyLogo}`}
                        sx={{
                          width: screenWidth > 480 ? 80 : 40,
                          height: screenWidth > 480 ? 80 : 40,
                          borderRadius: "100%",
                          margin: "20px",
                        }}
                      />
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 500, color: "#1C614E" }}
                        >
                          {data?.jobName}
                        </Typography>
                        <Typography variant="h7" sx={{ fontWeight: 500 }}>
                          {data?.employer}
                        </Typography>
                        {data?.experianceYears === 0 ? (
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#1C614E", mb: 1 }}
                          >
                            {Labels?.Fresher}
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">
                           {Labels.Experience}{" : " + data?.experianceYears}
                          </Typography>
                        )}

                        {data?.jobSkills ? (
                          <Stack direction="row">
                            <Typography variant="subtitle2">
                              {screenWidth > 415
                                ? Labels?.JobCategory + ":"
                                : ""}
                            </Typography>
                            <Chip
                              label={data?.jobCategory}
                              size="small"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                minWidth: 120,
                                fontSize: "1rem",
                                margin: "0px 10px",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </Stack>
                        ) : (
                          ""
                        )}
                        {/* {data.jobSkills ? (
                          <Stack direction="row">
                            <Typography variant="subtitle2">
                              {Labels?.skills}
                            </Typography>
                            &nbsp;
                            <Typography variant="subtitle2">
                              : {data.jobSkills.replace(/,/g, ", ")}
                            </Typography>
                          </Stack>
                        ) : (
                          ""
                        )} */}
                      </Box>
                    </Stack>

                    {/* {data.description ? (
                      <Typography
                        sx={{ fontSize: "14px", mb: 1, color: "#6c6c6c" }}
                      >
                        {data.description.length > 200
                          ? data.description.slice(0, 200) + "..."
                          : data.description}
                      </Typography>
                    ) : (
                      "" */}
                    {/* )} */}
                  </Box>
                ))
              )}
            </Stack>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "5px 0",
          }}
        >
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              // margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography variant="h6" sx={{ color: "#1C614E" }}>
                {Labels?.Forums}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1C614E",
                  color:"white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                    color:"white"
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/forumList")}
              >
                {Labels?.ViewAll}
              </Button>
            </div>
            <Divider />
            <Box sx={{ height: "35vh" }}>
              {loading2 ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : (
                myForum &&
                myForum.slice(0, 6).map((val, i) => (
                  <Card
                    key={i}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      borderBottom: "1px solid #1C614E33",
                      cursor: "pointer",
                      p: 0.5,

                      "&:hover": {
                        backgroundColor: "#ffffffe5",
                      },
                     
                    }}
                    onClick={() => viewDetailedForum(val.forumID)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={
                            val.logo === null || ""
                              ? `${dummyLogo}`
                              : `${val.logo}`
                          }
                          alt={val.forumName}
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Stack>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#2B4947", fontWeight: 600 }}
                          >
                            {val?.forumName}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <PublicIcon sx={{ fontSize: 17 }} />
                            <Typography
                              variant="body2"
                              sx={{ color: "#1C614E" }}
                            >
                              &nbsp;{val?.forumTopic}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant="caption">
                          {val?.createdOn}
                        </Typography>
                        {/* <RemoveRedEyeOutlinedIcon /> */}
                      </Stack>
                    </Stack>
                  </Card>
                ))
              )}
            </Box>
          </Card>
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography variant="h6" sx={{ color: "#1C614E" }}>
                {Labels?.Groups}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1C614E",
                  color:"white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                    color:"white"
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#1C614E",
                  },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/my-groupList")}
              >
                {Labels?.ViewAll}
              </Button>
            </div>
            <Divider />
            <Box sx={{ height: "40vh" }}>
              {loading3 ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : (
                groups &&
                groups.slice(0, 6).map((val, i) => (
                  <Card
                    key={i}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      borderBottom: "1px solid #1C614E33",
                      cursor: "pointer",
                      p: 0.5,
                      "&:hover": {
                        backgroundColor: "#ffffffe5",
                      },
                    
                    }}
                    onClick={() => viewDetailedGroup(val.id)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={
                            val.grpLogoPath === null || ""
                              ? `${dummyLogo}`
                              : `${val.grpLogoPath}`
                          }
                          alt=" "
                          style={{
                            marginLeft: 2,
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Stack>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#2B4947", fontWeight: 600 }}
                          >
                            {val.groupName}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <PublicIcon sx={{ fontSize: 17 }} />
                            <Typography
                              variant="body2"
                              sx={{ color: "#1C614E" }}
                            >
                              &nbsp;{val.categoryName}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant="caption">
                          {val.createdOn}
                        </Typography>
                        {/* <RemoveRedEyeOutlinedIcon sx={{ mr: 1 }} /> */}
                      </Stack>
                    </Stack>
                  </Card>
                ))
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>

      {/* <> 
  {resettedByAdmin === "1" ? (
          <EmployerPasswordChangeModal
           open={openPasswordReset}
           handleClose={setopenPasswordReset}
          />
        ) :null } 
  </> */}
      {/* <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <CustomButton
          label={Labels?.CreateJob}
          style={{ borderRadius: "4px" }}
          onClick={createJob}
        />
      </Stack> */}
    </>
  );
}
